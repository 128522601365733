import { api } from '../../../shared/services/backend-api'

const loginUser = async (credentials) => {
  const { email, password } = credentials
  const payload = {
    email,
    password
  }
  
  const { data: user } = await api.post('/login_check', payload)
  return user
}

const logoutUser = () => {
  return api.post('/logout')
}

const fetchCurrentUser = async () => {
  const {data} = await api.get(`/logged`)
  const { permissions, ...userInfo } = data
  return {permissions, userInfo}
}

const fetchPermissions = async () => {
  const { data } = await api.get('permissions')
  
  return data
}

const registerUser = (credentials) => {
  const {
    firstName: name,
    lastName: surname,
    password,
    isRegulations,
    isCommercialInformation,
    email
  } = credentials
  
  const payload = {
    name,
    surname,
    password,
    isRegulations,
    isCommercialInformation,
    email
  }
  
  return api.post('/users', payload)
}

const activateAccount = (token) => {
  return api.put(`/${token}/activate-account`)
}

const resetPassword = (credentials) => {
  return api.post('/reset-password', credentials)
}

const provideNewPassword = (payload) => {
  const { token, password } = payload
  
  return api.put(`/${token}/reset-password`, {password})
}


export {
  loginUser,
  logoutUser,
  registerUser,
  activateAccount,
  resetPassword,
  provideNewPassword,
  fetchCurrentUser,
  fetchPermissions
}
