<template>
  <el-container>
    <el-header>
      <v-top-navigation />
    </el-header>
    <div class="page-content" data-page-content>
      <slot>
        <router-view />
      </slot>
    </div>
    <VFooter />
  </el-container>
</template>

<script>
import { TOP_NAVIGATION_LAYOUT_NAME } from '../../shared/constants/layout-names'
import VTopNavigation from '../components/v-top-navigation/VTopNavigation'
import VFooter from '../components/v-footer/VFooter'

export default {
  name: TOP_NAVIGATION_LAYOUT_NAME,
  components: {
    VFooter,
    VTopNavigation,
  }
}
</script>

<style scoped>
.page-content {
  flex: 1 1 auto;
}
.el-container {
  min-height: 100vh;
}
</style>

