import { api } from '../../../shared/services/backend-api'
import {
  UNIT_TYPE_EMERGENCY,
  UNIT_TYPE_INSTITUTION,
  UNIT_TYPE_FAMILY
} from '../../../admin/units/shared/constants/units-constants'

const RESOURCE = 'units'

const normalizeUnitModel = (unit) => {
  const {
    children: totalChildren,
    supportedChildren,
    moneyPurpose,
    collectedMoney,
    monthlyCost,
    type: unitType,
    ...rest
  } = unit
  
  const normalizedUnit = {
    ...rest,
    unitType,
  }
  
  const progressAdapter = (total, progress) => {
    return {
      total,
      progress
    }
  }
  
  const PROGRESS_TYPES = {
    [UNIT_TYPE_FAMILY]: progressAdapter(totalChildren * monthlyCost, supportedChildren * monthlyCost),
    [UNIT_TYPE_INSTITUTION]: progressAdapter(totalChildren, supportedChildren),
    [UNIT_TYPE_EMERGENCY]: progressAdapter(moneyPurpose, collectedMoney)
  }
  
  normalizedUnit.progressData = PROGRESS_TYPES[unitType]
  
  return normalizedUnit
}

const fetchUnitPreviewData = async (unitID) => {
  const { data } = await api.get(`${RESOURCE}/${unitID}`)
  return normalizeUnitModel(data)
}

const fetchUnitPaymentInfo = async (unitID) => {
  const { data } = await api.get(`${RESOURCE}/${unitID}`)
  const {
    purposeTitle,
    moneyPurpose,
    collectedMoney,
    monthlyCost,
    children,
    type: unitType,
    supportedChildren,
    title: unitTitle
  } = data
  
  return {
    purposeTitle,
    moneyPurpose,
    collectedMoney,
    monthlyCost,
    children,
    unitType,
    supportedChildren,
    unitTitle
  }
}

const fetchUnitEditData = async (unitID) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { data } = await api.get(`${RESOURCE}/${unitID}/details`)
      const { type: unitType, description, title, hasParent } = data
      resolve({
        unitType,
        description,
        title,
        hasParent
      })
    }
    catch (e) {
      reject(e)
    }
  })
}

const updateUnit = async (unit) => {
  const { unitID, unitChildren, ...rest } = unit
  const payload = new FormData()
  
  Object.entries(rest)
    .forEach(([key, val]) => {
      payload.append(key, val)
    })
  payload.append('unitChildren', JSON.stringify(unitChildren))
  return api.post(`${RESOURCE}/${unitID}`, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
}

const updateUnitCardInfo = (unitID, content) => {
  return api.put(`${RESOURCE}/${unitID}/card-info`, {content})
}

const fetchUnitCardInfo = async (unitID) => {
  const { data } = await api.get(`${RESOURCE}/${unitID}/card-info`)
  
  return data.content
}

const fetchUnitPurposeList = async (unitID) => {
  const { data } = await api.get(`${RESOURCE}/${unitID}/goals`)
  
  return data
}

const fetchUnitActiveList = async (page, perPage) => {
  const { data } = await api.get(`${RESOURCE}/active`, {
    params: {
      page,
      perPage
    }
  })
  
  const { data: unitList,  totalRow: totalCount} = data
  
  const units = unitList.map((item) => normalizeUnitModel(item))
  
  return {units, totalCount}
}

const fetchAvailableUnitChildrenByUnitId = (unitId) => {
  return api.get(`${RESOURCE}/${unitId}/unit-children`)
}

export {
  fetchUnitPreviewData,
  fetchUnitCardInfo,
  fetchUnitEditData,
  fetchUnitPurposeList,
  updateUnit,
  updateUnitCardInfo,
  fetchUnitActiveList,
  fetchUnitPaymentInfo,
  fetchAvailableUnitChildrenByUnitId
}
