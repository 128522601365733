import {
  PURPOSE_PAYMENT_ROUTE_NAME,
  STANDING_ORDER_PAYMENT_ROUTE_NAME,
  ADOPTION_PAYMENT_ROUTE_NAME,
  COMPLETE_PAYMENT_ROUTE_NAME,
  RENEW_SUBSCRIPTION_ROUTE_NAME,
  FAMILY_PAYMENT_ROUTE_NAME
} from '../shared/constants/app-routes-names'

const PurposePayment = () => import('./purpose-payment/PurposePayment')
const StandingOrderPayment = () => import('./standing-order-payment/StandingOrderPayment')
const AdoptionPayment = () => import('./adoption-payment/AdoptionPayment')
const FamilyPayment = () => import('./family-payment/FamilyPayment')
const CompletePayment = () => import('./complete-payment/CompletePayment')
const RenewSubscription = () => import('./renew-subscription/RenewSubscription')


const paymentsRoutes = [
  {
    name: PURPOSE_PAYMENT_ROUTE_NAME,
    path: '/donate/:unitID',
    component: PurposePayment
  },
  {
    name: STANDING_ORDER_PAYMENT_ROUTE_NAME,
    path: '/standing-order/:unitID',
    component: StandingOrderPayment
  },
  {
    name: ADOPTION_PAYMENT_ROUTE_NAME,
    path: '/adopt/:unitID',
    component: AdoptionPayment
  },
  {
    name: FAMILY_PAYMENT_ROUTE_NAME,
    path: '/support-family/:unitID',
    component: FamilyPayment
  },
  {
    name: COMPLETE_PAYMENT_ROUTE_NAME,
    path: '/payment-status/:orderID',
    component: CompletePayment
  },
  {
    name: RENEW_SUBSCRIPTION_ROUTE_NAME,
    path: '/subscription/:subscriptionID/renew',
    component: RenewSubscription
  }
]

export {
  paymentsRoutes
}



