<template>
  <el-menu
    class="custom-menu"
    mode="horizontal"
    :router="true"
    :default-active="$route.name"
  >
    <el-menu-item
      :route="{ name: $options.PAGE_ABOUT_ORGANIZATION }"
      :index="$options.PAGE_ABOUT_ORGANIZATION"
    >
      {{ $t("navigation_menu.about_us") }}
    </el-menu-item>
    <el-menu-item
      :route="{ name: $options.PAGE_FAQ }"
      :index="$options.PAGE_FAQ"
    >
      {{ $t("navigation_menu.faq") }}
    </el-menu-item>
    <el-submenu v-if="showAdminModule" index="123">
      <template slot="title">
        {{ $t("navigation_menu.admin") }}
      </template>
      <can-i
        :permissions="rt.permissions"
        v-for="(rt, idx) in $options.adminRoutes"
        :key="idx"
      >
        <el-menu-item :route="{ name: rt.name }" :index="rt.name">
          {{ $t(rt.label) }}
        </el-menu-item>
      </can-i>
    </el-submenu>
    <el-submenu v-if="isLoggedIn" index="124">
      <template slot="title">
        {{ $t("navigation_menu.profile") }}
      </template>
      <can-i
        :permissions="rt.permissions"
        v-for="(rt, idx) in $options.profileRoutes"
        :key="idx"
      >
        <el-menu-item :route="{ name: rt.name }" :index="rt.name">
          {{ $t(rt.label) }}
        </el-menu-item>
      </can-i>
    </el-submenu>
    <el-menu-item v-else :route="{ path: '/login' }" :index="'/login'">
      {{ $t("navigation_menu.login") }}
    </el-menu-item>
  </el-menu>
</template>

<script>
import CanI from "../../../../../auth/can-i/CanI";
import {
  ROLE_UNIT_ADMINISTRATION,
  ROLE_USERS_ADMINISTRATION,
} from "../../../../../shared/constants/app-permissions";
import { mapGetters, mapState } from "vuex";
import {
  PAGE_FAQ,
  PAGE_ABOUT_ORGANIZATION,
} from "../../../../../shared/constants/app-routes-names";

import { adminRoutes, profileRoutes } from "../menu";

export default {
  name: "MenuDesktop",
  components: {
    CanI,
  },
  permissions: {
    ROLE_UNIT_ADMINISTRATION,
    ROLE_USERS_ADMINISTRATION,
  },
  adminRoutes,
  profileRoutes,
  PAGE_FAQ,
  PAGE_ABOUT_ORGANIZATION,
  methods: {
    atLeastOneIncluded(array, itemsToSearch) {
      const includeStatus = itemsToSearch.map((item) => array.includes(item));

      return includeStatus.includes(true);
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "authState/isLoggedIn",
    }),
    ...mapState("authState", {
      userPermissions: (state) => state.userPermissions,
    }),
    showAdminModule() {
      return this.atLeastOneIncluded(this.userPermissions, [
        ROLE_USERS_ADMINISTRATION,
        ROLE_UNIT_ADMINISTRATION,
      ]);
    },
  },
};
</script>
