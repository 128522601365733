<template>
  <el-row class="layout-wrapper">
    <el-col class="p-8" :span="24" :lg="8">
      <el-col :lg="{span: 12, offset: 6}">
        <h2 class="heading page-heading">{{ formTitle }}</h2>
        <slot />
      </el-col>
    </el-col>
    <el-col :lg="16" class="hidden-md-and-down">
      <div class="image-area" :style="backgroundImage">
        <div class="image-area__text-wrapper">
          <h1 class="image-area__main-title">
            {{ title }}
          </h1>
          <h2 class="image-area__description">
            {{ description }}
          </h2>
        </div>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import {RIGHT_SIDE_IMAGE_LAYOUT_NAME} from '../../shared/constants/layout-names'

export default {
  name: RIGHT_SIDE_IMAGE_LAYOUT_NAME,
  data() {
    return {
      image: '',
      title: '',
      description: '',
      formTitle: ''
    }
  },
  provide() {
    return {
      useLayoutContent: this.changeLayoutContent
    }
  },
  methods: {
    changeLayoutContent({image, title, description, formTitle}) {
      this.image = image
      this.title = title
      this.description = description
      this.formTitle = formTitle
    }
  },
  computed: {
    backgroundImage() {
      return { 'background-image': `url(${this.image})`, }
    }
  }
}
</script>

<style lang="scss" scoped>
  .layout-wrapper {
    min-height: 100vh;
  }

  .form-title {
    font-weight: 700;
  }

  .image-area {
    background: 50%/cover no-repeat fixed;
    height: 100vh;
    position: relative;
    display: flex;
    flex: 5 1 auto;
    flex-wrap: wrap;
    padding: 0 5rem;
    &__text-wrapper {
      color: #fff;
      align-self: center;
    }
    &__main-title {
      color: inherit;
      font-weight: 700;
      font-size: 4.9em;
      margin-bottom: 0;
    }
    &__description {
      font-size: 2em;
      margin-top: 10px;
      color: inherit;
    }
  }
</style>
