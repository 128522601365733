<template>
  <div role="button"
       @click="toggleActive"
       class="sandwich"
       :class="{ 'is-active': isActive }"
  >
    <div class="sandwich__line sandwich__line--top"></div>
    <div class="sandwich__line sandwich__line--middle"></div>
    <div class="sandwich__line sandwich__line--bottom"></div>
  </div>
</template>

<script>
export default {
  name: 'VSandwich',
  props: {
    isActive: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggleActive() {
      this.$emit('update:is-active', !this.isActive)
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.sandwich {
  display: none;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  height: 27px;
  width: 27px;
  @include smartphone {
    display: block;
  }
  &.is {
    &-active {
      .sandwich__line {
        &--top {
          top: 10px;
          transform: rotate(45deg);
        }
        &--middle { opacity: 0; }
        &--bottom {
          top: 10px;
          transform: rotate(-45deg);
        }
      }
    }
  }
  &__line {
    background-color: black;
    display: block;
    height: 3px;
    left: 0;
    position: absolute;
    transition: all linear .2s;
    width: 100%;
    &--top { top: 0; }
    &--middle { top: 9px; }
    &--bottom { top: 18px; }
  }
}
</style>
