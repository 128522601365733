import {api} from '../../../../shared/services/backend-api'
import i18n from '../../../../plugins/i18n'

const RESOURCE = 'units'

const createUnit = async (form) => {
  const payload = new FormData()
  const {unitChildren, ...unitInfo} = form
  Object.entries(unitInfo)
    .forEach(([key, val]) => {
      payload.append(key, val)
    })
  payload.append('unitChildren', JSON.stringify(unitChildren))
  const { data } = await api.post(`${RESOURCE}`, payload,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
  
  return data
}

const fetchAdminUnitList = async () => {
  const { data } = await api.get(`${RESOURCE}`)
  
  const unitList = data.data.map(({type, ...rest}) => ({
    type,
    typeTrans: i18n.t(`unitTypes.${type}`),
    ...rest
  }))
  
  return unitList
}

const publishUnit = (unitID) => {
  return api.put(`${RESOURCE}/${unitID}/publish`)
}

const deactivateUnit = (unitID) => {
  return api.put(`${RESOURCE}/${unitID}/unpublish`)
}

const fetchAvailableUnitChildren = () => {
  return api.get(`${RESOURCE}/unit-children`)
}

export {
  createUnit,
  fetchAdminUnitList,
  publishUnit,
  deactivateUnit,
  fetchAvailableUnitChildren
}
