import {
  PROFILE_ORDERS_ROUTE_NAME,
  PROFILE_ACCOUNT_ROUTE_NAME,
  PROFILE_VOLUNTEER_ROUTE_NAME,
} from '../shared/constants/app-routes-names';

const UserProfile = () => import('./UserProfile');
const ProfileOrders = () => import('./profile-orders/ProfileOrders');
const ProfileUserData = () => import('./profile-user-data/ProfileUserData');
const ProfileVolunteer = () => import('./profile-volunteer/ProfileVolunteer');

const profileRoutes = [
  {
    path: '/profile',
    component: UserProfile,
    redirect: {
      name: PROFILE_ACCOUNT_ROUTE_NAME,
    },
    children: [
      {
        path: 'orders',
        name: PROFILE_ORDERS_ROUTE_NAME,
        component: ProfileOrders,
      },
      {
        path: 'account',
        name: PROFILE_ACCOUNT_ROUTE_NAME,
        component: ProfileUserData,
      },
      {
        path: 'volunteer',
        name: PROFILE_VOLUNTEER_ROUTE_NAME,
        component: ProfileVolunteer,
      },
    ],
  },
];

export { profileRoutes };
