<template>
  <component :is='layout'>
      <slot />
  </component>
</template>

<script>
import {TOP_NAVIGATION_LAYOUT_NAME} from '../shared/constants/layout-names'

import AppLayoutTopNavigation from './AppLayoutTopNavigation/AppLayoutTopNavigation'
import AppLayoutRightSideImage from './AppLayoutRightSideImage/AppLayoutRightSideImage'
import AppLayoutCenterContainer from './AppLayoutCenterContainer/AppLayoutCenterContainer'
import AppLayoutBanner from './app-layout-banner/AppLayoutBanner'
import UnitLayout from './unit-layout/UnitLayout'

const DEFAULT_LAYOUT = TOP_NAVIGATION_LAYOUT_NAME

export default {
  name: 'AppLayout',
  components: {
    AppLayoutRightSideImage,
    AppLayoutTopNavigation,
    AppLayoutCenterContainer,
    UnitLayout,
    AppLayoutBanner
  },
  computed: {
    layout() {
      return this.$route.meta.layout || DEFAULT_LAYOUT
    },
  },
}
</script>
