const copyToClipboard = (str) => {
  return new Promise((resolve, reject) => {
    if (!navigator.clipboard) {
      reject('Clipboard is not supported by your browser')
    }
  
    const toCopyStr = String(str)
    navigator.clipboard.writeText(toCopyStr)
      .then(() => {
        resolve('clipboard.copy_success')
      })
      .catch(e => {
        reject(['clipboard.copy_fail', e])
      })
  })
  
  
  
}


export {
  copyToClipboard
}
