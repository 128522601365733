<template>
  <el-footer height="43">
    <div class="footer">
      <div class="footer__item">
        {{ $options.copyright }}
      </div>
      <div class="footer__item">
        <el-link :href="$options.TERM_OF_REGULATION_DOCUMENT"
                 :underline="false">
          {{ $t('components.footer.terms_link_text') }}
        </el-link>
      </div>
      <div class="footer__item">
        <el-link :underline="false"
                 :href="$options.PRIVACY_POLICY_DOCUMENT">
          {{ $t('components.footer.privacy_link_text') }}
        </el-link>
      </div>
      <div class="footer__item">
        <el-link :underline="false"
                 :href="$options.SALVATTI_REGULATION_DOCUMENT">
          {{ $t('components.footer.salvatti_regulation_link_text') }}
        </el-link>
      </div>
      <div class="footer__item">
        <el-link :underline="false"
                 @click="$router.push({name: $options.PAGE_CONTACT_ROUTE_NAME})">
          {{ $t('components.footer.contact_link_text') }}
        </el-link>
      </div>
    </div>
  </el-footer>
</template>

<script>
import {
  SALVATTI_REGULATION_DOCUMENT,
  TERM_OF_REGULATION_DOCUMENT,
  PRIVACY_POLICY_DOCUMENT
} from '../../../shared/constants/app-documents'

import {PAGE_CONTACT_ROUTE_NAME} from '../../../shared/constants/app-routes-names'

export default {
  name: 'VFooter',
  TERM_OF_REGULATION_DOCUMENT,
  PRIVACY_POLICY_DOCUMENT,
  PAGE_CONTACT_ROUTE_NAME,
  SALVATTI_REGULATION_DOCUMENT,
  copyright: `Copyright © ${new Date().getFullYear()} salvatti.pl`
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.footer {
  user-select: none;
  height: 100%;
  margin: 0 -20px;
  padding: 12px 24px;
  border-top: 1px solid #E4E7ED;
  display: flex;
  align-items: center;
  @include smartphone {
    flex-direction: column;
  }
  .el-link {
    font-weight: 400;
    color: #303133;
  }
  &__item {
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
    @include smartphone {
      margin-right: 0;
      margin-bottom: 1em;
    }
  }
}
</style>
