const DEFAULT_CURRENCY = 'PLN'
const DEFAULT_COUNTRY = 'pl-PL'
const INTL_PLN_CURRENCY_STR = 'zł'

const moneyFormat = (countMoney, options = {}) => {
  const value = Number(countMoney)
  const formatter = new Intl.NumberFormat(
    DEFAULT_COUNTRY,
    {
      style: 'currency',
      currency: DEFAULT_CURRENCY,
      maximumFractionDigits: 0,
      ...options
    })
  const formatValue = formatter.format(value)
  return formatValue.replace(INTL_PLN_CURRENCY_STR, DEFAULT_CURRENCY)
}

export {
  moneyFormat
}
