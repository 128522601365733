import { authRoutes } from './auth/auth-routes'
import { clientRoutes } from './client/client-routes'
import { adminRoutes } from './admin/admin-routes'
import { paymentsRoutes } from './payments/payments-routes'
import { profileRoutes } from './profile/profile-routes'
import {servicePagesRoutes} from './service-pages/service-pages-routes'

const appRoutes = [
  ...authRoutes,
  ...clientRoutes,
  ...adminRoutes,
  ...paymentsRoutes,
  ...profileRoutes,
  ...servicePagesRoutes,
]

export {
  appRoutes,
}
