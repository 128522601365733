import {
  POST_CREATE_ROUTE_NAME,
  POST_EDIT_ROUTE_NAME,
  ADMIN_UNIT_LIST_ROUTE_NAME,
  ADMIN_USER_LIST_ROUTE_NAME,
  ADMIN_UNIT_GALLERY_ROUTE_NAME,
  ADMIN_VOLUNTEERS_ROUTE_NAME,
  ADMIN_FINANCE_REPORT_ROUTE_NAME,
  ADMIN_QUARTER_REPORT_ROUTE_NAME,
  ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME,
  ADMIN_SLIDER_MANAGE
} from '../shared/constants/app-routes-names'

import {ROLE_USERS_ADMINISTRATION} from '../shared/constants/app-permissions'

const UnitCreate =  () => import('./units/unit-create/UnitCreate')
const UnitEdit =  () => import('./units/unit-edit/UnitEdit')
const UnitEditDescription =  () => import('./units/unit-edit-description/UnitEditDescription')
const UnitList = () => import('./units/unit-list/UnitList')
const UnitAdoptionEdit = () => import('./units/unit-adoption-edit/UnitAdoptionEdit')
const UnitPurposeEdit = () =>  import('./units/unit-purpose-edit/UnitPurposeEdit')
const PostCreate = () => import('./posts/post-create/PostCreate')
const PostEdit = () => import('./posts/post-edit/PostEdit')
const UserList = () => import('./users/user-list/UserList')
const UnitGalleryManage = () => import('./units/unit-gallery-manage/UnitGalleryManage')
const VolunteerList = () => import('./volunteers/volunteer-list/VolunteerList')
const FinanceReport = () => import('./reports/finance-report/FinanceReport')
const QuarterReports = () => import('./reports/quarter-reports/QuarterReports')
const AddQuarterReport = () => import('./reports/quarter-reports/add-report/AddReport')
const SliderManage = () => import('./slider-manage/SliderManage')

const adminRoutes = [
  {
    path: '/units/create',
    name: 'create_unit',
    component: UnitCreate
  },
  {
    path: '/units',
    name: ADMIN_UNIT_LIST_ROUTE_NAME,
    component: UnitList
  },
  {
    path: '/units/:unitID/edit',
    name: 'edit_unit',
    component: UnitEdit
  },
  {
    path: '/unit/:unitID/about/edit',
    name: 'edit_unit_info',
    component: UnitEditDescription
  },
  {
    path: '/unit/:unitID/adoption/:adoptionID/edit',
    name: 'edit_adoption',
    component: UnitAdoptionEdit
  },
  {
    path: '/unit/:unitID/purpose/:purposeID/edit',
    name: 'edit_purpose',
    component: UnitPurposeEdit
  },
  {
    path: '/unit/:unitID/posts/create',
    name: POST_CREATE_ROUTE_NAME,
    component: PostCreate
  },
  {
    path: '/unit/:unitID/posts/:postID/edit',
    name: POST_EDIT_ROUTE_NAME,
    component: PostEdit
  },
  {
    path: '/users',
    name: ADMIN_USER_LIST_ROUTE_NAME,
    component: UserList,
    meta: {
      requirePermission: ROLE_USERS_ADMINISTRATION
    }
  },
  {
    path: '/unit/:unitID/gallery/manage',
    name: ADMIN_UNIT_GALLERY_ROUTE_NAME,
    component: UnitGalleryManage
  },
  {
    path: '/volunteers',
    name: ADMIN_VOLUNTEERS_ROUTE_NAME,
    component: VolunteerList
  },
  {
    name: ADMIN_FINANCE_REPORT_ROUTE_NAME,
    path: '/reports/finance',
    component: FinanceReport
  },
  {
    name: ADMIN_QUARTER_REPORT_ROUTE_NAME,
    path: '/reports/quarter',
    component: QuarterReports
  },
  {
    name: ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME,
    path: '/reports/quarter/add',
    component: AddQuarterReport
  },
  {
    name: ADMIN_SLIDER_MANAGE,
    path: '/slider-manage',
    component: SliderManage
  },
  
]

export {
  adminRoutes
}
