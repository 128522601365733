<template>
  <el-button v-bind="$attrs" v-on="$listeners" class="el-button--tile">
    <div class="btn-content">
      <v-icon v-if="icon" :size="iconSize" :name="icon">
        <template #default>
          <slot name="icon" />
        </template>
      </v-icon>
      <div class="el-button__label">
        <slot />
      </div>
    </div>
  </el-button>
</template>

<script>
import VIcon from '../v-icon/VIcon'

export default {
  name: 'VTileButton',
  components: {
    VIcon
  },
  props: {
    icon: {
      type: String,
      default: undefined
    }
  },
  inject: ['responsive'],
  computed: {
    iconSize() {
      return this.responsive.isMd ? '2em' : '2.8em'
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.el-button {
  width: 90%;
  padding: 1em 1em;
  @include tablet {
    padding: 8px 16px;
    max-width: 300px;
    .btn-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__label {
    margin-top: 3px;
    @include tablet {
      margin: 0 3px 0;
    }
  }
}
</style>
