<template>
  <AppLayoutTopNavigation>
    <section :style="imageStyle" class="page-banner">
      <h2 class="page-banner__heading heading">{{ pageTitle }}</h2>
    </section>
    <div class="app-container">
      <router-view />
    </div>
  </AppLayoutTopNavigation>
</template>

<script>
import AppLayoutTopNavigation from '../AppLayoutTopNavigation/AppLayoutTopNavigation'
import {BANNER_LAYOUT_NAME} from '../../shared/constants/layout-names'

export default {
  name: BANNER_LAYOUT_NAME,
  components: {AppLayoutTopNavigation},
  data() {
    return {
      pageTitle: '',
      imageUrl: require('../../../assets/images/bg-register.jpg')
    }
  },
  methods: {
    changePageTitle(title) {
      this.pageTitle = this.$t(title)
    },
    changeImage(imgUrl) {
      this.imageUrl = imgUrl
    }
  },
  provide() {
    return {
      changePageTitle: this.changePageTitle,
      changePageBanner: this.changeImage
    }
  },
  computed: {
    imageStyle() {
      return {
        background: `url('${this.imageUrl}') no-repeat center center/cover`,
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .page-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10em 0;
    &__heading {
      color: #ffffff;
      font-size: 4.2em;
    }
  }
</style>
