import Vue from 'vue'
import VueResponsiveProvide from 'vue-provide-responsive'

Vue.use(VueResponsiveProvide, {
  breakpoints: {
    lg: 1920,
    md: 1200,
    sm: 992,
    xs: 768,
  }
})
