import Vue from 'vue'
import VueRouter from 'vue-router'
import { appRoutes } from './app/app-routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: appRoutes
})

export default router
