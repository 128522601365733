import { UNIT_LAYOUT_NAME } from '../../shared/constants/layout-names'

import {
  UNIT_ABOUT_ROUTE_NAME,
  UNIT_PURPOSES_ROUTE_NAME,
  UNIT_POSTS_ROUTE_NAME,
  POST_VIEW_ROUTE_NAME,
  UNIT_GALLERY_ROUTE_NAME
} from '../../shared/constants/app-routes-names'

const UnitAbout =  () => import('./unit-about/UnitAbout')
const UnitPurposes = () => import('./unit-purposes/UnitPurposes')
const UnitPosts = () => import('./unit-posts/UnitPosts')
const UnitPostView = () => import('./unit-post-view/UnitPostView')
const UnitGallerySlideshow = () => import('./unit-gallery-slideshow/UnitGallerySlideshow')

const unitRoutes = [
  {
    path: '/unit/:unitID/about',
    name: UNIT_ABOUT_ROUTE_NAME,
    component: UnitAbout,
    meta: {
      layout: UNIT_LAYOUT_NAME
    }
  },
  {
    path: '/unit/:unitID/purposes',
    name: UNIT_PURPOSES_ROUTE_NAME,
    component: UnitPurposes,
    meta: {
      layout: UNIT_LAYOUT_NAME
    }
  },
  {
    path: '/unit/:unitID/posts',
    name: UNIT_POSTS_ROUTE_NAME,
    component: UnitPosts,
    meta: {
      layout: UNIT_LAYOUT_NAME
    }
  },
  {
    path: '/unit/:unitID/posts/:postID',
    name: POST_VIEW_ROUTE_NAME,
    component: UnitPostView,
    meta: {
      layout: UNIT_LAYOUT_NAME
    }
  },
  {
    path: '/unit/:unitID/gallery',
    name: UNIT_GALLERY_ROUTE_NAME,
    component: UnitGallerySlideshow,
    meta: {
      layout: UNIT_LAYOUT_NAME
    }
  },
]

export {
  unitRoutes
}
