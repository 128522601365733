import {
  ADMIN_FINANCE_REPORT_ROUTE_NAME,
  ADMIN_QUARTER_REPORT_ROUTE_NAME, ADMIN_SLIDER_MANAGE,
  ADMIN_UNIT_LIST_ROUTE_NAME,
  ADMIN_USER_LIST_ROUTE_NAME,
  ADMIN_VOLUNTEERS_ROUTE_NAME,
  PROFILE_ACCOUNT_ROUTE_NAME,
  PROFILE_ORDERS_ROUTE_NAME,
  PROFILE_VOLUNTEER_ROUTE_NAME,
  USER_LOGOUT_ROUTE_NAME
} from '../../../../shared/constants/app-routes-names'
import {
  ROLE_ACCOUNT_DATA_MANAGEMENT,
  ROLE_UNIT_ADMINISTRATION,
  ROLE_USERS_ADMINISTRATION, ROLE_VOLUNTEERING_MANAGEMENT
} from '../../../../shared/constants/app-permissions'

const adminRoutes = [
    {
      label: 'navigation_menu.unit_list',
      name: ADMIN_UNIT_LIST_ROUTE_NAME,
      permissions: [ROLE_UNIT_ADMINISTRATION]
    },
    {
      label: 'navigation_menu.user_list',
      name: ADMIN_USER_LIST_ROUTE_NAME,
      permissions: [ROLE_USERS_ADMINISTRATION]
    },
    {
      label: 'navigation_menu.volunteers',
      name: ADMIN_VOLUNTEERS_ROUTE_NAME,
      permissions: [ROLE_USERS_ADMINISTRATION]
    },
    {
      label: 'navigation_menu.finance_report',
      name: ADMIN_FINANCE_REPORT_ROUTE_NAME,
      permissions: [ROLE_USERS_ADMINISTRATION]
    },
    {
      label: 'navigation_menu.quarter_report',
      name: ADMIN_QUARTER_REPORT_ROUTE_NAME,
      permissions: [ROLE_USERS_ADMINISTRATION]
    },
  {
      label: 'navigation_menu.banner',
      name: ADMIN_SLIDER_MANAGE,
      permissions: [ROLE_USERS_ADMINISTRATION]
    },
  ]

const profileRoutes = [
  {
    label: 'navigation_menu.profile_routes.account',
    name: PROFILE_ACCOUNT_ROUTE_NAME,
    permissions: [ROLE_ACCOUNT_DATA_MANAGEMENT]
  },
  {
    label: 'navigation_menu.profile_routes.payments',
    name: PROFILE_ORDERS_ROUTE_NAME,
    permissions: [ROLE_ACCOUNT_DATA_MANAGEMENT]
  },
  {
    label: 'navigation_menu.profile_routes.volunteer',
    name: PROFILE_VOLUNTEER_ROUTE_NAME,
    permissions: [ROLE_VOLUNTEERING_MANAGEMENT, ROLE_ACCOUNT_DATA_MANAGEMENT]
  },
  {
    label: 'navigation_menu.profile_routes.logout',
    name: USER_LOGOUT_ROUTE_NAME,
    permissions: [ROLE_ACCOUNT_DATA_MANAGEMENT]
  }
]

export {adminRoutes, profileRoutes}
