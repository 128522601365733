<template>
  <div :style="backgroundImage" class="unit-banner">
    <div :class="{
      'app-container': !isMd,
      'unit-banner__container': isMd
    }">
      <el-row :gutter="24">
        <el-col class="d-flex align-center unit-banner__content"
                :lg="{ span: 14, offset: 6 }"
        >
          <h2 class="unit-title">
            {{ unitTitle }}
          </h2>
          <can-i :permissions="[$options.ROLE_UNIT_ADMINISTRATION]">
            <div class="unit-banner__actions">
              <v-button type="primary" icon="edit" @click="goToEdit">
                Edytuj zbiórkę
              </v-button>
              <v-button :loading="isPublishInProgress" type="primary" icon="publish" @click="publish">
                Opublikuj zbiórkę
              </v-button>
            </div>
          </can-i>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import {UPLOADS_FOLDER_URL} from '../../../shared/constants/backend'
import VButton from '../../../components/ui/v-button/VButton'
import {publishUnit} from '../../../admin/units/shared/services/unit-service'
import CanI from '../../../auth/can-i/CanI'
import {ROLE_UNIT_ADMINISTRATION} from '../../../shared/constants/app-permissions'

export default {
  name: 'UnitBanner',
  ROLE_UNIT_ADMINISTRATION,
  components: {CanI, VButton},
  props: {
    unitBannerName: {
      type: String,
    },
    unitTitle: {
      type: String
    }
  },
  data() {
    return {
      isPublishInProgress: false
    }
  },
  inject: ['responsive'],
  computed: {
    backgroundImage() {
      return {
        backgroundImage: `url('${UPLOADS_FOLDER_URL}${this.unitBannerName}')`
      }
    },
    isMd() {
      return this.responsive.isMd
    }
  },
  methods: {
    publish() {
      this.isPublishInProgress = true
      const {unitID} = this.$route.params

      publishUnit(unitID).then(() => {
        this.$message({
          type: 'success',
          showClose: false,
          message: `${this.$t('page_unit.messages.unit_publish_success')}`
        })
      }).catch(() => {
            this.$message({
              type: 'error',
              showClose: false,
              message: `${this.$t('page_unit.messages.unit_publish_error')}`
            })
          })
          .finally(() => {
            this.isPublishInProgress = false
          })
    },
    goToEdit() {
      const {unitID} = this.$route.params
      this.$router.push({name: 'edit_unit', params: {unitID}})
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.unit-banner {
  padding: 300px 15px 8px;
  background: no-repeat center center/cover;
  position: relative;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    height: 100%;
    width: 100%;
    z-index: 1;
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(9,9,121,0) 0%, rgba(0,0,0,0.38559173669467783) 100%);
  }


  @include tablet {
    padding: 100px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  @include smartphone {
    padding: 160px 0;
  }
  &__content {
    z-index: 100;
    @include tablet {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }

  &__actions {
    @include tablet {
      margin-top: 1em;
    }
  }
}

.unit-title {
  font-size: 2.8em;
  color: #ffffff;
  margin: 0 12px 0 0;
  @include smartphone {
    font-size: 1.8em;
  }
}
</style>
