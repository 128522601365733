import {
  UNIT_TYPE_EMERGENCY,
  UNIT_TYPE_INSTITUTION,
  UNIT_TYPE_FAMILY,
} from '../admin/units/shared/constants/units-constants';

import {
  PROFILE_ORDERS_ROUTE_NAME,
  PROFILE_ACCOUNT_ROUTE_NAME,
  PROFILE_VOLUNTEER_ROUTE_NAME,
} from '../shared/constants/app-routes-names';

import {
  SINGLE_PAYMENT_TYPE,
  SUBSCRIPTION_PAYMENT_TYPE,
  STANDING_ORDER_PAYMENT_TYPE,
} from '../shared/constants/payment-types';

import {
  STATUS_WAITING,
  STATUS_VERIFIED,
  STATUS_ERROR,
} from '../shared/constants/payment-statuses';

import {
  USER_STATUS_ACTIVE,
  USER_STATUS_BLOCKED,
  USER_STATUS_NOT_ACTIVE,
} from '../shared/constants/user-statuses';

import {
  ADMIN_OPERATION,
  PAYMENT_HEARTH_ADOPTION_CYCLIC,
  PAYMENT_HEARTH_ADOPTION_ONE_TIME,
  PAYMENT_PURPOSE,
} from '../profile/constants/balance-operations-types';

const pl = {
  forms: {
    email: 'E-mail',
    password: 'Hasło',
    first_name: 'Imię',
    last_name: 'Nazwisko',
  },
  clipboard: {
    copy_success: 'Skopiowano do schowka',
    copy_error: 'Nie udało się skopiować. Spróbuj ręcznie',
  },
  components: {
    file_input: {
      files: 'plików',
    },
    image_upload_modal: {
      btn_open: 'btn_open',
      btn_cancel: 'btn_cancel',
      title: 'title',
      upload_text: 'upload_text',
      upload_text_highlighted: 'upload_text_highlighted'
    },
    date_picker: {
      start_placeholder: 'Okres od',
      end_placeholder: 'Okres do',
    },
    scroll_pagination: {
      btn: 'Pokaż więcej',
    },
    dialog: {
      btn_submit: 'Zapisz',
      btn_cancel: 'Anuluj',
    },
    unit_info: {
      alerts: {
        error: 'Błąd podczas pobierania informacji o zbiórce',
      },
    },
    footer: {
      terms_link_text: 'Regulamin',
      privacy_link_text: 'Polityka prywatności',
      contact_link_text: 'Kontakt',
      salvatti_regulation_link_text: 'Statut Fundacji'
    },
    volunteer_input: {
      tooltip: `Wprowadź numer wolontariusza a Fundacja, <b>z własnych środków</b>, zasili budżet przeznaczony na refundację kosztów i rozwój wolontariatu. Wsparcie wolontariuszy <b>nie zmniejsza</b> wysokości wsparcia, które przekażemy docelowym adresatom Twojej pomocy.`,
    },
  },
  validators: {
    field_required: 'Pole jest wymagane',
    incorrect_email: 'Nieprawidłowy format adresu e-mail',
    max_file_size_exceed: 'Plik musi być mniejszy niż {maxSize} Mb',
    cannot_be_empty: 'Nie można zapisać z pustym polem',
    password: {
      symbols:
        'Hasło musi zawierać co najmniej 1 wielką i małą literę, 1 znak specjalny i 1 cyfrę',
      min_length: 'Hasło musi zawierać min {count} znaków',
    },
    number_value_cant_be_zero: 'Wartość musi być różną od 0',
    number_value_above_zero: 'Wartość musi być większa od 0',
    number_value_positive: 'Wartość nie może być liczbą ujemną',
    number_value_required: 'Wartość musi być liczbą',
    volunteer_code_input: 'Podany kod wolontariusza nie istnieje',
  },
  alerts: {
    register_email_sent: 'Wysłaliśmy Ci wiadomość z linkiem do aktywacji konta',
    user_email_exists: 'Ten adres e-mail jest już używany',
    reset_password_email_sent:
      'Wysłaliśmy Ci wiadomość z linkiem do zmiany hasła',
    new_password_set:
      'Twoje hasło zostało zmienione. Za chwilę przekierujemy Cię do strony logowania',
    login_invalid_credentials: 'Nieprawidłowy login lub hasło',
    login_attempts_exceeded:
      'Przekroczona liczba prób logowania. Spróbuj ponownie za 15 min.',
    session_expired: 'Wymagane zalogowanie',
    access_denied: 'Brak uprawnień',
    common_error: '{status}: Wystąpił błąd. Spróbuj później',
    account_blocked: 'Konto zostało zablokowane',
    payments: {
      unit_unpublish:
        'Nie można dokonać wpłaty, bo zbiórka została już zamknięta.',
    },
  },
  navigation_menu: {
    main_page: 'Strona główna',
    faq: 'Q&A',
    admin: 'Admin',
    about_us: 'Salvatti EDU',
    create_unit: 'Dodaj zbiórkę',
    unit_list: 'Zbiórki',
    user_list: 'Użytkownicy',
    volunteers: 'Wolontariusze',
    finance_report: 'Finanse',
    quarter_report: 'Raporty cykliczne',
    banner: 'Baner',
    login: 'Zaloguj się',
    profile: 'Moje konto',
    profile_routes: {
      logout: 'Wyloguj',
      account: 'Mój profil',
      payments: 'Moje finanse',
      volunteer: 'Mój wolontariat',
    },
  },
  page_not_found: {
    title: 'Nie znaleziono strony',
    btn_main_page: 'Przejdź do strony głównej',
  },
  page_adoption_payment: {
    title: 'Wspieram regularnie dzieci',
    require_login: {
      title: 'Prosimy, zaloguj się na swoje konto',
      description:
        'Adopcja serca polega na regularnym wspieraniu finansowym dzieci, którym można zarządzać za pomocą swojego konta w serwisie.',
      btn_register: 'Stwórz konto',
      btn_login: 'Już mam konto',
    },
    card_info: {
      unit_title: 'Nazwa zbiórki',
      unit_children_difference: 'Liczba dzieci oczekujących wsparcia',
    },
    payment_types: {
      one_time_payment: 'Jednorazowy przelew za wybraną ilość miesięcy',
      one_time_payment_short: 'Jednorazowy przelew (x miesięcy)',
      cyclic_payment: 'Subskrypcja z pobieraniem co miesiąc z karty',
      cyclic_payment_short: 'Comiesięczne pobranie z karty',
    },
    fields: {
      radio_group_children: 'Ile dzieci chcesz objąć wsparciem?',
      radio_children: '{count} dziecko | {count} dzieci',
      radio_children_custom: 'Inna liczba',
      children: 'Wpisz liczbę dzieci',
      months: 'Wybierz ilość miesięcy',
      radio_month: '{count} miesiąc | {count} miesiące | {count} miesięcy',
      volunteer_code: 'Wpisz numer wolontariusza',
    },
    max_children_adoption:
      'Maksymalna liczba dzieci, które możesz adoptować to {count}',
    adoption_not_allowed:
      'Dziękujemy, ale w chwili obecnej wszystkie dzieci w placówce {title} objęte są adopcją',
    btn_submit: 'Przejdź do płatności',
    btn_cancel: 'Powrót',
    btn_volunteer: 'Wolontariat',
  },
  subscription_calculator: {
    labels: {
      [UNIT_TYPE_INSTITUTION]: {
        bricks: 'Dzieci',
        monthly_cost: 'Koszt utrzymania',
        months: 'Okres mies.',
        one_time_amount: 'Kwota płatności',
        cyclic_amount: 'Kwota za 1 miesiąc',
      },
      [UNIT_TYPE_FAMILY]: {
        bricks: 'L. cegiełek',
        monthly_cost: 'Koszt cegiełki',
        months: 'Okres mies.',
        one_time_amount: 'Kwota płatności',
        cyclic_amount: 'Twoje wsparcie miesięczne',
      },
    },
  },
  page_family_payment: {
    title: 'Wspieram regularnie',
    card_info: {
      unit_title: 'Nazwa zbiórki',
      unit_bricks_difference: 'Brakujące wsparcie',
    },
    payment_types: {
      one_time_payment: 'Jednorazowy przelew za wybraną ilość miesięcy',
      one_time_payment_short: 'Jednorazowy przelew (x miesięcy)',
      cyclic_payment: 'Subskrypcja z pobieraniem co miesiąc z karty',
      cyclic_payment_short: 'Comiesięczne pobranie z karty',
    },
    require_login: {
      title: 'Prosimy, zaloguj się na swoje konto',
      description:
        'Wspieranie regularne realizuje się w ramach subskrypcji, którymi można zarządzać za pomocą swojego konta w serwisie',
      btn_register: 'Stwórz konto',
      btn_login: 'Już mam konto',
    },
    fields: {
      radio_group_bricks: 'Ile cegiełek wsparcia chcesz sfinansować?',
      radio_bricks: '{count} cegiełka | {count} cegiełki',
      radio_bricks_custom: 'Inna liczba',
      bricks: 'Wpisz liczbę cegiełek',
      months: 'Wybierz ilość miesięcy',
      radio_month: '{count} miesiąc | {count} miesiące | {count} miesięcy',
    },
    btn_submit: 'Przejdź do płatności',
    btn_cancel: 'Powrót',
    btn_volunteer: 'Wolontariat',
    max_brick_count_exceeded:
      'Maksymalna wysokość miesięcznego wsparcia to {amount}',
    subscription_not_allowed:
      'Dziękujemy, ale cel wsparcia regularnego został osiągnięty',
  },
  standing_order_payment: {
    payment_data: {
      title: "Dane do przelewu",
      receiver: "Odbiorca",
      account_no: "Nr. rachunku fundacji",
      amount: "Kwota",
      payment_title: "Tytuł wpłaty",

      action: {
        copy_receiver: "Kopiuj dane odbiorcy",
        copy_account_no: "Kopiuj numer konta",
        copy_amount: "Kopiuj kwotę",
        copy_payment_title: "Kopiuj tytuł wpłaty"
      }

    },
    card_info: {
      unit_volunteer_id: "Wskazany numer wolontariusza",
      provided_email: "Twój adres e-mail"
    },
    btn_get_pdf: "Pobierz PDF"
  },
  page_purpose_payment: {
    title: {
      [UNIT_TYPE_EMERGENCY]: 'Pomagam sfinansować pilną potrzebę',
      [UNIT_TYPE_INSTITUTION]: 'Pomagam w utrzymaniu i rozwoju placówki',
      [UNIT_TYPE_FAMILY]: 'Wspieram doraźnie',
    },
    agreement: {
      text: 'Zapoznałem/am się i akceptuję',
      link_text: 'Regulamin serwisu',
    },
    standing_order: {
      text: 'Chcę ustanowić stałe zlecenie w swoim banku',
    },
    card_info: {
      unit_title: 'Nazwa zbiórki',
      unit_purpose_title: 'Aktualny cel',
      unit_money_difference: 'Brakuje do sfinansowania celu',
    },
    validators: {
      max_money_value: 'Maksymalna kwota płatnosci jest {maxValue} PLN',
    },
    fields: {
      amount: 'Wpisz kwotę',
      email: 'Podaj adres e-mail',
      volunteer_code: 'Wpisz numer wolontariusza',
    },
    btn_submit: 'Przejdź do płatności',
    btn_cancel: 'Powrót',
  },
  page_complete_payment: {
    [STATUS_ERROR]: {
      title: 'Transakcja nieudana',
      description: 'Transakcja zakończyła się niepowodzeniem',
    },
    [STATUS_WAITING]: {
      title: 'Dziękujemy za wpłatę',
      description:
        'Trwa weryfikacja płatności. Sprawdź status płatności w mailu od Przelewy24',
    },
    [STATUS_VERIFIED]: {
      title: 'Dziękujemy za wpłatę',
      description: 'Płatność została potwierdzona',
    },
  },
  unit_list: {
    title: 'Wybierz swój projekt',
  },
  page_unit_create: {
    validation_errors: {
      photo: 'Za duża miniatura',
      banner: 'Za duże tło',
    },
    load_unit_children_error: 'Nie udało się pobrać zbiórek do podpięcia',
    title: 'Tworzenie zbiórki',
    fields: {
      unit_type_institution: 'Adopcja serca',
      unit_type_emergency: 'Emergency',
      unit_type_family: 'Rodzina',
      unit_name: 'Nazwa zbiórki',
      unit_description: 'Opis (do strony głównej)',
      background_image: 'Tło (1920x350)',
      background_image_placeholder: 'Wybierz plik (maks. {size}Mb)',
      image_thumbnail: 'Miniatura (285x215)',
      image_thumbnail_placeholder: 'Wybierz plik (maks. {size}Mb)',
      unit_children: 'Podepnij zbiórkę',
      unit_children_label: 'Podpięte zbiórki'
    },
    btn_create: 'Stwórz',
    btn_cancel: 'Anuluj',
  },
  page_admin_units: {
    title: 'Lista zbiórek',
    btn_create_unit: 'Dodaj zbiórkę',
    btn_deactivate_unit: 'Zamknij',
    btn_unit_preview: 'Podgląd',
    confirm_modal: {
      btn_confirm: 'Tak',
      btn_cancel: 'Nie, anuluj',
      confirm_text:
        'Czy na pewno chcesz zamknąć zbiórkę? WSZYSTKIE aktualne SUBSKRYPCJE zostaną anulowane',
    },
    alerts: {
      deactivate_unit_success: 'Zbiórka została zamknięta',
    },
    table_headers: {
      title: 'Nazwa',
      type: 'Typ zbiórki',
      children: 'Liczba',
      monthlyCost: 'Koszt / mc',
      purposeTitle: 'Aktualny cel',
      moneyPurpose: 'Kwota celu',
      purposeDateStart: 'Start celu',
      publishDate: 'Publikacja zbiórki',
      actions: 'Akcje',
    },
  },
  page_description_edit: {
    title: 'Edycja wizytówki',
  },
  page_adoption_edit: {
    [UNIT_TYPE_INSTITUTION]: {
      title: 'Edycja parametrów adopcyjnych',
      fields: {
        children: 'Liczba dzieci do adopcji',
        monthlyCost: 'Koszt miesięcznego utrzymania dziecka',
        content: 'Opis',
      },
    },
    [UNIT_TYPE_FAMILY]: {
      title: 'Edycja parametrów wsparcia regularnego',
      fields: {
        children: 'Liczba cegiełek',
        monthlyCost: 'Wartość jednostkowej cegiełki',
        content: 'Opis',
      },
    },
    messages: {
      update_success: 'Parametry zostały zaktualizowane',
    },
    btn_submit: 'Zapisz',
    btn_cancel: 'Anuluj',
  },
  page_purpose_edit: {
    title: 'Edycja celu wsparcia ośrodka',
    fields: {
      title: 'Nazwa celu',
      money: 'Kwota do zebrania',
      startedAt: 'Data startu raportowania',
      content: 'Opis',
    },
    btn_submit: 'Zapisz',
    btn_cancel: 'Anuluj',
    messages: {
      update_success: 'Cel został zaktualizowany',
    },
  },
  page_unit_edit: {
    title: 'Edycja zbiórki "{unitTitle}"',
    messages: {
      edit_success: 'Zbiórka została zaktualizowana',
      load_unit_children_error: 'Nie udało się pobrać zbiórek do podpięcia'
    },
    unit_children_has_parent: 'Ta zbiórka jest podrzędna w relacji z inną zbiórką nadrzędną - odepnij ją jeśli chcesz z tej zbiórki uczynić zbiórkę nadrzędną',
    fields: {
      unit_type_institution: 'Adopcja serca',
      unit_type_emergency: 'Emergency',
      unit_name: 'Nazwa zbiórki',
      unit_description: 'Opis (do strony głównej)',
      background_image: 'Tło (1920x350)',
      background_image_placeholder: 'Wybierz plik (maks. {size}Mb)',
      image_thumbnail: 'Miniatura (285x215)',
      image_thumbnail_placeholder: 'Wybierz plik (maks. {size}Mb)',
      unit_children: 'Podepnij zbiórkę',
      unit_children_label: 'Podpięte zbiórki'
    },
    btn_update: 'Zapisz',
    btn_cancel: 'Anuluj',
  },
  page_main: {
    title: 'Wiemy jak skutecznie pomagać',
    service: '(SERWIS W PRZYGOTOWANIU)',
    subtitle: 'Pomagamy każdemu dziecku być tym, kim chce',
  },
  unit_navigation: {
    unit_about: 'Wizytówka',
    unit_purposes: 'Cele',
    unit_posts: 'Posty',
    unit_gallery: 'Galeria',
  },
  page_unit: {
    post_status: {
      published: 'Publiczny',
      unpublished: 'Ukryty',
    },
    empty_states: {
      unit_about: {
        subtitle: 'Strona wizytówki jest pusta',
        description:
          'Opisz i zilustruj placówkę lub cel zbiórki korzystając z edytora ukrytego pod ikoną „Edytuj wizytówkę”',
      },
      unit_posts: {
        subtitle: 'Nie dodano jeszcze żadnego postu',
        description:
          'Dodaj pierwszy i kolejne posty przybliżające nowe informacje o ośrodku lub celu, który został objęty wsparciem Fundacji',
      },
      unit_gallery: {
        subtitle: 'Nie dodano jeszcze żadnego zdjęcia',
        description:
          'Wklej i opisz kilka zdjęć, dzięki którym pomożemy zrozumieć sens organizacji tej zbiórki',
      },
    },
    messages: {
      unit_publish_success: 'Zbiórka została opublikowana',
    },
    [UNIT_TYPE_INSTITUTION]: {
      btn_adoption: 'Wspieram regularnie',
      btn_unit_support: 'Wspieram doraźnie',
      btn_bricks_edit: 'Edytuj Adopcję',
      purpose_subscription_title: 'Wsparcie regularne dla dzieci',
    },
    [UNIT_TYPE_EMERGENCY]: {
      btn_unit_support: 'Wspieram',
    },
    [UNIT_TYPE_FAMILY]: {
      btn_unit_support: 'Wspieram doraźnie',
      btn_bricks_edit: 'Edytuj wsparcie',
      purpose_subscription_title: 'Wsparcie regularne',
    },
    btn_create_post: 'Dodaj post',
    btn_copy_unit_link: 'Kopiuj link zbiórki',
    btn_family_support: 'Wspieram regularnie',
    btn_adoption: 'Wspieram regularnie',
    btn_edit_card_info: 'Edytuj wizytówkę',
  },
  page_post_view: {
    btn_edit: 'Edytuj post',
    btn_delete: 'Usuń post',
    btn_publish: 'Opublikuj post',
    btn_unpublish: 'Ukryj post',
    messages: {
      remove_success: 'Post został usunięty',
      remove_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
      publish_success: 'Post został opublikowany',
      unpublish_success: 'Post został ukryty',
    },
    popover_delete: {
      btn_confirm: 'Tak, usuń',
      btn_cancel: 'Nie',
      confirm_text: 'Czy na pewno chcesz usunąć post?',
    },
    popover_publish: {
      btn_confirm: 'Tak, opublikuj',
      btn_cancel: 'Nie',
      confirm_text:
        'Czy na pewno chcesz opublikować ten post? Będzie widoczny dla wszystkich',
    },
    popover_unpublish: {
      btn_confirm: 'Tak, ukryj',
      btn_cancel: 'Nie',
      confirm_text:
        'Czy na pewno chcesz ukryć ten post? Będzie dostępny tylko pod linkiem',
    },
  },
  page_post_update: {
    title: 'Edycja postu',
    btn_submit: 'Zapisz',
    btn_cancel: 'Anuluj',
    messages: {
      update_success: 'Post został zaktualizowany',
      update_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
    },
    fields: {
      title: 'Tytuł',
      photo: 'Okładka',
      description: 'Krótki opis',
      content: 'Treść postu',
    },
  },
  unitTypes: {
    [UNIT_TYPE_EMERGENCY]: 'Emergency',
    [UNIT_TYPE_INSTITUTION]: 'Adopcja',
    [UNIT_TYPE_FAMILY]: 'Rodzina',
  },
  unitProgressLabels: {
    [UNIT_TYPE_EMERGENCY]: {
      currentProgress: 'Zebrano',
      difference: 'Brakuje',
    },
    [UNIT_TYPE_INSTITUTION]: {
      currentProgress: 'Wspierane dzieci',
      difference: 'Oczekujące wsparcia',
    },
    [UNIT_TYPE_FAMILY]: {
      currentProgress: 'Uzyskane wsparcie',
      difference: 'Brakujące wsparcie',
    },
  },
  page_register: {
    form_title: 'Rejestracja',
    btn_submit: 'Zarejestruj się',
    terms_text_1: 'Zapoznałem/am się i akceptuję',
    terms_link_text: 'Regulamin serwisu',
    commercial_information_text:
      'Wyrażam zgodę na przesyłanie mi informacji marketingowych dotyczących produktów i usług oferowanych przez "PALLOTYŃSKA FUNDACJA MISYJNA SALVATTI.PL" za pomocą środków komunikacji elektronicznej, stosownie do treści przepisu art. 10 ust. 1 i 2 ustawy o świadczeniu usług drogą elektroniczną.',
    login_link: 'Zaloguj się',
    register_info_link:
      'Klauzula informacyjna o przetwarzaniu danych osobowych',
    register_info_pdf: 'klauzula_informacyjna_adopcja_edu_rejestracja.pdf',
  },
  page_login: {
    form_title: 'Logowanie',
    forgot_password_link: 'Zapomniałeś hasła?',
    register_link: 'Zarejestruj się',
    btn_submit: 'Zaloguj się',
    rules_info: 'Logowanie oznacza akceptację',
    rules_link: 'Regulaminu serwisu',
    alerts: {
      login_invalid_credentials: 'Nieprawidłowy login lub hasło',
    },
  },
  page_reset_password: {
    form_title: 'Przypomnij hasło',
    btn_submit: 'Wyślij',
  },
  page_set_password: {
    form_title: 'Ustal nowe hasło',
    btn_submit: 'Zmień',
  },
  profile_navigation: {
    [PROFILE_ORDERS_ROUTE_NAME]: 'Moje finanse',
    [PROFILE_ACCOUNT_ROUTE_NAME]: 'Moje dane',
    [PROFILE_VOLUNTEER_ROUTE_NAME]: 'Mój wolontariat',
  },
  page_profile_data: {
    personal_data: {
      title: 'Moje dane',
      fetch_error: '',
      labels: {
        name: 'Imię',
        surname: 'Nazwisko',
        email: 'E-mail',
      },
      btn_edit: 'Zmień dane',
      edit_data: {
        btn_save: 'Zapisz',
        btn_cancel: 'Anuluj',
        modal_title: 'Zmień dane',
        messages: {
          success: 'Dane zostały zaktualizowane',
          error: 'Nie udało się zaktualizować dane',
        },
      },
    },
    security: {
      title: 'Bezpieczeństwo',
      messages: {
        reset_password_success:
          'Wysłaliśmy Ci wiadomość z linkiem do zmiany hasła',
        reset_password_failed: 'Nie udało się wysłać e-mail, spróbuj później',
      },
      btn_reset: 'Zmień hasło',
      labels: {
        password: 'Hasło',
      },
    },
    agreements: {
      title: 'Moje zgody',
      btn_save: 'Zapisz zmiany',
      messages: {
        success: 'Zgody zostały zaktualizowane',
        error: 'Nie udało się zaktualizować zgody',
      },
    },
  },
  page_profile_orders: {
    payment_history: {
      title: 'Historia wpłat',
      table_headers: {
        unit_title: 'Zbiórka',
        purpose: 'Cel',
        amount: 'Kwota',
        action: 'Twoja decyzja',
        payment_date: 'Data',
      },
      action_types: {
        [SINGLE_PAYMENT_TYPE]: 'Wpłata doraźna',
        [SUBSCRIPTION_PAYMENT_TYPE]: 'Subskrypcja',
        [STANDING_ORDER_PAYMENT_TYPE]: 'Stałe zlecenie',
      },
    },
    subscriptions_history: {
      title: 'Moje subskrypcje',
      messages: {
        cancel_success: 'Subskrypcja została anulowana',
        cancel_error: 'Nie udało się anulować subskrypcji',
      },
      table_headers: {
        date_start: 'Data od',
        unit_title: 'Zbiórka',
        supported_children: 'Dzieci',
        monthly_cost: 'Kwota / mc',
        date_end: 'Data do',
      },
      btn_cancel: 'Anuluj',
      btn_renew: 'Przedłuż',
      confirm_modal: {
        btn_confirm: 'Tak, anuluj',
        btn_cancel: 'Nie',
        confirm_text: 'Czy na pewno chcesz anulować subskrypcję?',
      },
      endless_subscription: 'Bezterminowa',
    },
    annual_report: {
      title: 'Sprawozdanie roczne',
      download_report_button: 'Pobierz',
      filename: 'Sprawozdanie roczne {date}.{extension}',
      download_report_tooltip: 'Sprawozdanie z darowizn wpłaconych w danym roku kalendarzowym dostępne jest do pobrania od 20 stycznia roku następnego',
      table_headers: {
        unit_title: 'Zbiórka',
        orders_sum: 'Suma wpłat',
      },
    },
  },
  page_profile_volunteer: {
    my_id: {
      title: 'Moje ID',
      labels: {
        volunteer_id: 'Mój numer identyfikacyjny',
      },
    },
    my_account: {
      title: 'Mój rachunek',
      labels: {
        donations_raised: 'Pozyskane darowizny',
        current_balance: 'Saldo rachunku',
        total_charges: 'Suma naliczeń',
        admin_operations: 'Saldo operacji Admina',
        donations_tooltip: 'Suma darowizn wpłaconych na zbiórki realizowane w serwisie Salvatti EDU z wykorzystaniem mojego numeru wolontariusza'
      },
    },
    account_history: {
      title: 'Historia rachunku',
      table_headers: {
        title: 'Zbiórka/Tytuł',
        operation_type: 'Typ operacji',
        amount: 'Kwota',
        balance: 'Saldo',
        transaction_date: 'Data',
      },
      operation_types: {
        [ADMIN_OPERATION]: 'Operacja admina',
        [PAYMENT_HEARTH_ADOPTION_CYCLIC]: 'Subskrypcja karta',
        [PAYMENT_HEARTH_ADOPTION_ONE_TIME]: 'Subskrypcja z wpłatą jednorazową',
        [PAYMENT_PURPOSE]: 'Przelew jednorazowy',
      },
    },
  },
  page_post_create: {
    title: 'Tworzenie postu',
    btn_submit: 'Stwórz',
    btn_cancel: 'Anuluj',
    messages: {
      create_error: 'Błąd podczas tworzenia postu, spróbuj później',
    },
    fields: {
      title: 'Tytuł',
      photo: 'Okładka',
      description: 'Krótki opis',
      content: 'Treść postu',
    },
  },
  page_admin_users: {
    title: 'Lista użytkowników',
    table_headers: {
      name: 'Imię',
      surname: 'Nazwisko',
      email: 'E-mail',
      paymentSum: 'Suma wpłat',
      role: 'Rola',
      status: 'Status',
      actions: 'Akcje',
    },
    actions_dropdown: {
      action_change_role: 'Zmień rolę',
      action_change_status: 'Zmień status',
    },
    change_role_dialog: {
      title: 'Zmiana roli',
      select_label: 'Rola',
      messages: {
        change_success: 'Rola została zmieniona',
        change_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
      },
    },
    change_status_dialog: {
      title: 'Zmiana statusu',
      select_label: 'Status',
      select_option_block: 'Zablokowany',
      select_option_active: 'Aktywny',
      messages: {
        change_success: 'Status użytkownika został zaktualizowany',
        change_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
      },
    },
    user_statuses: {
      [USER_STATUS_ACTIVE]: 'Aktywny',
      [USER_STATUS_BLOCKED]: 'Zablokowany',
      [USER_STATUS_NOT_ACTIVE]: 'Nieaktywny',
    },
  },
  page_unit_bank_statements_manage: {
    upload_modal: {
      title: 'Dodaj nowy wyciąg',
      upload_text: 'Przeciągnij plik tutaj lub ',
      upload_text_highlighted: 'kliknij by załadować',
      btn_open: 'Dodaj nowy wyciąg bankowy',
      btn_cancel: 'Anuluj',
      btn_confirm: 'Dodaj',
    },

  },
  page_unit_gallery_manage: {
    title: 'Zarządzanie galerią',
    btn_remove: 'Usuń zdjęcie',
    btn_back: 'Powrót',
    image_name_label: 'Podpis zdjęcia',
    upload_modal: {
      title: 'Dodaj nowe zdjęcia',
      upload_text: 'Przeciągnij plik tutaj lub ',
      upload_text_highlighted: 'kliknij by załadować',
      btn_open: 'Dodaj nowe',
      btn_cancel: 'Anuluj',
      btn_confirm: 'Dodaj',
    },
    popover_delete: {
      btn_cancel: 'Nie',
      btn_confirm: 'Tak, usuń',
      confirm_text: 'Czy na pewno chcesz usunąć zdjęcie?',
    },
    messages: {
      remove_success: 'Zdjęcie zostało usunięte',
      edit_success: 'Zdjęcie zostało zmodyfikowane',
      remove_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
      edit_error: 'Wystąpił błąd. Zmiany nie zostały zapisane.',
    },
  },
  page_subscription_renew: {
    title: 'Przedłużenie subskrypcji',
    card_info: {
      [UNIT_TYPE_FAMILY]: {
        unit_title: 'Nazwa zbiórki',
        bricks_to_subscribe: 'Liczba Twoich cegiełek',
        bricks: '{count}',
        price_old: 'Dotychczasowy koszt',
        price_new: 'Aktualny koszt',
        price_unit: 'PLN/cegiełka/mc',
        expiration_date: 'Data ważności',
      },
      [UNIT_TYPE_INSTITUTION]: {
        unit_title: 'Nazwa zbiórki',
        bricks_to_subscribe: 'Finansujesz utrzymanie',
        bricks: '{count} dziecko | {count} dzieci',
        price_old: 'Dotychczasowy koszt',
        price_new: 'Aktualny koszt',
        price_unit: 'PLN/dziecko/mc',
        expiration_date: 'Data ważności',
      },
    },
    pricing: {
      label_new: 'Akceptuję nowe koszty',
      label_old: 'Wybieram dotychczasowe koszty',
    },
  },
  page_admin_volunteers: {
    title: 'Lista wolontariuszy',
    actions: {
      admin_operation: 'Operacja Admina',
    },
    table_headers: {
      name: 'Imię',
      surname: 'Nazwisko',
      email: 'E-mail',
      reference_code: 'Kod',
      donations_raised: 'Suma darowizn',
      total_charges: 'Suma naliczeń',
      total_admin_operations: 'Operacje Admina',
      current_balance: 'Saldo rachunku',
    },
    admin_operation_modal: {
      title: 'Operacja na rachunku "{fullName}"',
      messages: {
        success: 'Operacja została wykonana',
      },
      fields: {
        operation_title: 'Tytuł operacji',
        operation_amount: 'Kwota',
      },
      btn_cancel: 'Anuluj',
      btn_confirm: 'Wykonaj',
    },
  },
  page_finance_report: {
    btn_subscription_report: 'Subskrypcje XLS',
    btn_orders_report: 'Wpłaty XLS',
    btn_import_statement: 'Importuj wyciąg bankowy',
    title: 'Finanse',
    date_picker: {
      start_placeholder: 'Wpłaty od',
      end_placeholder: 'Wpłaty do',
    },
    table_headers: {
      title: 'Zbiórka',
      unit_type: 'Typ',
      adoption: 'Regularne',
      purpose_support: 'Doraźne',
      support_payment_total: 'Wpłaty TOTAL',
      subscription_payment_total: 'Subskrypcje TOTAL',
    },
  },
  page_quarter_report: {
    title: 'Raporty cykliczne',
    btn_add_report: 'Dodaj raport',
    btn_preview: 'Pokaż',
    dialog_info: {
      title: 'Wysłany tekst',
      btn_close: 'Zamknij',
      btn_post_preview: 'Zobacz post',
    },
    table_headers: {
      unit_title: 'Nazwa zbiórki',
      date_range: 'Okres',
      date_send: 'Data wysłania',
    },
  },
  page_add_quarter_report: {
    title: 'Wysyłanie raportu',
    btn_cancel: 'Anuluj',
    btn_submit: 'Wyślij',
    btn_preview: 'Wyślij testowo',
    preview_modal: {
      title: 'Wyślij raport testowo',
      field_name: 'Imię',
      field_email: 'E-mail',
      btn_close: 'Anuluj',
      btn_submit: 'Wyślij',
    },
    send_success: 'Raport został wysłany',
    fields: {
      unit_title: 'Zbiórka',
      date_range: 'Data start - data koniec',
      post: 'Post',
      email_content: 'Treść maila',
    },
  },
  page_unit_gallery: {
    btn_manage: 'Zarządzaj',
  },
  page_contact: {
    title: 'Kontakt',
  },
  page_adoption_static: {
    title: 'Salvatti EDU',
  },
  page_faq: {
    title: 'Garść informacji',
    schema: [
      {
        title: 'Fundacja',
        children: [
          {
            title: 'Kim są Pallotyni?',
            content:
              'Księża Pallotyni (Stowarzyszenie Apostolstwa Katolickiego) od dziesięcioleci skutecznie łączą apostolstwo i rozkrzewianie wiary z budowaniem zaplecza i organizacją struktur zaangażowanych osób świeckich. Dzięki temu, obecnie mają pod swoją opieką liczne, prężnie działające ośrodki misyjne w wielu krajach tzw. Globalnego Południa, wokół których tworzone są szkoły, przedszkola, placówki medyczne i przedsięwzięcia biznesowe, które trwale poprawiają jakość życia lokalnych społeczności.',
          },
          {
            title: 'Czym jest Fundacja Salvatti.pl?',
            content:
              'Pallotyńska Fundacja Misyjna Salvatti.pl odpowiada za organizacyjną i finansową stronę realizowanych projektów. Trwałe, pozytywne rezultaty wymagają niejednokrotnie ogromnych nakładów na budowę i utrzymanie infrastruktury - szkół, świetlic, studni, stołówek etc... oraz ciągłego pozyskiwania finansowania na realizację kolejnych przedsięwzięć.',
          },
          {
            title: 'Schemat działania Fundacji?',
            content:
              'Celem wsparcia Fundacji jest uzyskiwanie trwałych oczekiwanych rezultatów, dlatego konieczne jest skoordynowane działanie w dwóch kierunkach. Pierwszy to oczywiście organizacja funduszy, które umożliwiają start - np: budowę szkoły, drugi, to aktywizacja lokalnej społeczności w taki sposób, aby ta szkoła wypełniła się życiem i stała się, docelowo, integralnym i samowystarczalnym elementem miejscowego pejzażu. Takie rozumienie pomocy wymaga każdorazowo pomysłowości, indywidualnego podejścia oraz obecności na miejscu umożliwiającej właściwe rozpoznanie lokalnej specyfiki, potencjału i potrzeb.',
          },
          {
            title: 'Dlaczego warto pomagać razem z Fundacją?',
            content:
              'Warto pomagać w ogóle, natomiast fundacja Salvatti konsekwentnie rozwija swoją ofertę dla tych Darczyńców, którzy chcą być możliwie blisko wspieranych projektów - chcą widzieć precyzyjnie zdefiniowane cele i rezultaty uzyskiwane dzięki swojemu zaangażowaniu. Niniejszy serwis został zaprojektowany właśnie w taki sposób, by wyjść naprzeciw tym oczekiwaniom - sprawdź wdrożone funkcjonalności, które wspierają efektywne i transparentne pomaganie.',
          },
        ],
      },
      {
        title: 'Ukraina',
        children: [
          {
            title: 'Wyzwanie',
            content:
              'Wojna w Ukrainie może spowodować kryzys humanitarny o trudnej do przewidzenia skali. Nieprawdopodobny, improwizowany zryw Polaków skierowany na pomoc uchodźcom z Ukrainy, w dłuższej perspektywie czasu, musi zostać zastąpiony systemem, porządkującym i koordynującym pomoc na każdym szczeblu działania. Fundacja zdecydowała o dostosowaniu swojej działalności w taki sposób, by móc świadczyć pomoc bezpośrednio tam gdzie nie dotarła jeszcze pomoc systemowa bądź jest ona niewystarczająca dla zapewnienia godnych warunków życia potrzebującym.',
          },
          {
            title: 'Adresaci naszej pomocy',
            content:
              'Naszą pomoc kierujemy zarówno do rodzin jak i organizacji, które podjęły się wysiłku niesienia pomocy osobom poszkodowanym przez wojnę w Ukrainie. Rodziny zarówno ukraińskie, które zyskały w Polsce tymczasowe schronienie lub polskie, które przyjęły pod swój dach uchodźców i potrzebują w związku z tym stałego wsparcia finansowego. Organizacje - wszelkie fundacje, stowarzyszenia, parafie, przedsiębiorstwa etc, działające zarówno w Polsce jak i w Ukrainie, które realizują działania pomocowe na rzecz osób poszkodowanych przez działania wojenne i które poszukują stałego wsparcia materialnego umożliwiającego realizację podjętych zobowiązań.',
          },
          {
            title: 'Nasz cel',
            content:
              'Chcemy wypełniać lukę pomiędzy dniem dzisiejszym a przyszłą stabilizacją. Z pewnością kiedyś skończy się wojna, uchodźcy zasymilują się z naszym społeczeństwem albo wrócą odbudowywać swój kraj. Dzisiaj podejmujemy jednak starania, by jak największej liczbie osób zapewnić godne warunki codziennego życia i wsparcie w procesie adaptacji.',
          },
          {
            title: 'Schemat działania',
            content: `Działamy według następującego scenariusza:
            <br />
            <br />1 - Dotarcie do rodziny bądź organizacji świadczącej pomoc na rzecz osób poszkodowanych przez wojnę.
            <br />2 - Analiza potrzeb, zorganizowanie zbiórki w ramach portalu Salvatti EDU,
            <br />3 - Promocja zbiórki przez Fundację oraz osoby, zainteresowane, w szybkim osiągnięciu ustalonego poziomu wsparcia,
            <br />4 - Organizacja optymalnego procesu przekazywania pomocy,
            <br />5 - Monitoring sytuacji, reagowanie na zmiany, sprawozdania dla Darczyńców,
            <br />6 - Uzyskanie samowystarczalności finansowej (podjęcie pracy, 500+, inne systemowe wsparcie), ewentualnie powrót rodziny do Ukrainy,
            <br />7 - Zakończenie współpracy, zamknięcie zbiórki.
            `,
          },
          {
            title: 'Mam rodzinę ukraińską pod swoim dachem',
            content: `
              To wielka sprawa. Jeśli potrzebujesz wsparcia napisz do nas mail na adres: pomoc@salvatti.edu.pl - w tytule "Pomoc dla Ukrainy", a w treści maila, krótko opisz swoją sytuację:
              <br />
              <br />
              1. Przekaż nam swoje imię i nazwisko, wskaż miejscowość, w której mieszkasz,
              <br />
              2. Napisz kilka słów o osobach które gościsz,
              <br />
              3. Zostaw nam swój numer telefonu. Najszybciej jak to będzie możliwe, skontaktuje się z Tobą nasz wolontariusz i działamy :-)
            `
          },
          {
            title: 'Jesteśmy organizacją pomagającą uchodźcom',
            content: `Jeżeli potrzebujecie wsparcia finansowego, prosimy opiszcie mailowo krótką swoją sytuację na pomoc@salvatti.edu.pl - w tytule "Pomoc dla Ukrainy".
            W treści maila podajcie również imię i nazwisko oraz telefon do osoby reprezentującej Waszą organizację - niezwłocznie skontaktujemy się z Wami.`
          },
          {
            title: 'Chcę włączyć się w pomoc dla uchodźców z Ukrainy',
            content: `
              Możesz to zrobić na kilka sposobów:
              <br />
              <br />
              1. Zostań Darczyńcą - wybierz projekt dedykowany Ukrainie (wyróżniamy je flagą ukraińską w dolnym rogu zdjęcia ilustrującego zbiórkę),
              poznaj historię wpieranej rodziny bądź danego ośrodka oraz ich aktualne potrzeby finansowe i wybierz sposób pomagania z tych przewidzianych dla danego projektu.
              <br />
              2. Promuj konkretną zbiórkę - wybierz historię, która szczególnie chwyciła Cię za serce, skopiuj link do zbiórki i roześlij do swoich przyjaciół w mediach społecznościowych.
              <br />
              3. Zostań wolontariuszem - jeżeli możesz poświęcić jakąś część swojego czasu, jesteś dobrze zorganizowany i sumienny,
              ogarniasz narzędzia typu Facebook ... dostrzegasz u siebie gen przedsiębiorczości, nie przeraża Cię improwizacja - zapraszamy :-) (więcej w sekcji "Wolontariat i współpraca")
            `
          },
        ],
      },
      {
        title: 'Finanse',
        children: [
          {
            title: 'Jak ustalamy cele i wielkość oczekiwanego wsparcia?',
            content: 'Zawsze na podstawie analizy potrzeb konkretnego przypadku przy wykorzystaniu filtrów zdrowego rozsądku, doświadczenia i kontekstu, co oznacza,' +
              ' że nie wszystkie zgłoszone potrzeby będziemy firmować w ramach realizowanych zbiórek. W afrykańskich ośrodkach misyjnych, które wspieramy od lat,' +
              ' mamy doskonałą orientację w potrzebach lokalnej społeczności; nasze wsparcie dla uchodźców chcemy rozwijać przy wsparciu wolontariuszy, którzy będą mogli rozpoznać rzeczywiste potrzeby, ' +
              'ale również podpowiedzą ewentualne możliwości uruchomienia innych źródeł finansowania. ' +
              'Uruchomione zbiórki rozpoczyna ustawiczny proces monitorowania sytuacji i reagowania na zmieniające się potrzeby. ',
          },
          {
            title: 'W jaki sposób przekazywana jest pomoc wspieranym rodzinom i ośrodkom.',
            content: 'Zgodnie z logiką i charakterem świadczonej pomocy w konkretnym przypadku. ' +
              'Darowizny przekazujemy w taki sposób, by optymalnie realizowały swoje funkcje. Ośrodki misyjne wspieramy transferami finansowymi, podobnie ośrodki polskie, ' +
              'które podjęły się wsparcia dla uchodźców, chyba że preferowaną formą pomocy jest dostarczenie konkretnego asortymentu. ' +
              'Pomoc dla rodzin realizujemy według podobnego schematu na podstawie indywidualnych ustaleń. ' +
              'W przypadku, gdy pomoc ma trafiać bezpośrednio do osób nie posiadających obywatelstwa polskiego, zalecamy skorzystanie z uproszczonych ' +
              'procedur zakładania rachunków uruchomionych w niektórych bankach w Polsce.',
          },
          {
            title: 'Jak kontrolujemy wykorzystanie środków?',
            content: `
              Z zachowaniem odpowiednich proporcji dla wielkości angażowanych środków w ramach konkretnego projektu. Budowa szkoły w Bivouvoue w Kamerunie oczywiście wiąże się ze skrupulatnym planowaniem finansowym i rozliczaniem każdego poniesionego wydatku, również pod nadzorem działających na miejscu misjonarzy i wolontariuszy. Finansując działalność świetlicy środowiskowej w Casa Hoga w Kolumbii, monitorujemy realizację zakładanego programu i np: realizację planowanego zakupu stołu do ping ponga.
              <br />
               Wspierając rodzinę ukraińską, która uciekła z Krzywego Rogu jedynie w ubraniach, które miała na sobie, kwotą 2.000 zł miesięcznie, koncentrujemy się jedynie na właściwym rozpoznaniu potrzeb i dyskretnej dbałości o ich godne zaadoptowanie się do nowych warunków. Aktualizując sekcję Posty, Galeria oraz w ramach cyklicznie rozsyłanych raportów, dokładamy starań by Darczyńcy byli na bieżąco informowani o rezultatach ich wsparcia finansowego.
            `,
          },
          {
            title: 'Jakie koszty finansowane są ze środków zbieranych w ramach Serwisu?',
            content: 'Nie mniej niż 90% wpłaconych środków trafia do adresatów pomocy, zatem nie więcej niż 10% przeznaczane jest na cele statutowe czyli finansowanie kosztów ponoszonych przez Fundację, ' +
              'z których najistotniejsze to: prowizje operatorów płatności, koszty zagranicznych transferów pieniężnych, koszty utrzymania i rozwoju Serwisu, koszty działań promujących Serwis, ' +
              'koszty wolontariatu oraz tworzenie tzw. funduszu Emergency umożliwiającego podejmowanie natychmiastowych działań pomocowych. ' +
              'Publikowane cele finansowe w ramach realizowanych zbiórek uwzględniają już te koszty.',
          },
        ],
      },
      {
        title: 'Dla Darczyńców',
        children: [
          {
            title: 'Po co mi konto w Serwisie?',
            content: `
              Posiadanie konta w Serwisie daje następujące korzyści:
              <br />
              1. Możliwość uruchomienia i zarządzania subskrypcjami czyli cyklicznym finansowaniem wybranych projektów,
              <br />
              2. Dostęp do historii zrealizowanych wpłat,
              <br />
              3. Roczne sprawozdania - wsparcie rozliczania darowizn w rocznych PIT-ach ,
              <br />
              4. Możliwość otrzymywania cykliczych raportów opisujących uzyskane rezultaty.
            `,
          },
          {
            title: 'Jak mogę pomagać za pośrednictwem Serwisu?',
            content: `
               Po pierwsze, finansowo - Serwis dla takiej formuły pomagania został zaprojektowany. Wybierz jeden z opublikowanych projektów, kliknij w ikonkę z opisem i wejdź na stronę zbiórki gdzie,
               w kolejnych zakładkach, znajdziesz szczegółowe opisy, uzasadnienie finansowego celu zbiórki, posty i galerię zdjęć. Gdy uznasz,
               że znalazłeś rodzinę lub ośrodek, który chcesz wesprzeć finansowo skorzystaj z ikonek i zdefiniuj charakter i wielkość swojej darowizny.
               <br />
               Po drugie, zasięgowo - jeżeli docenisz nasz model działania, podziel się swoimi spostrzeżeniami ze swoją rodziną i swoimi znajomymi.
               Zachęcaj do odwiedzenia naszego Serwisu albo do zapoznania się z ofertą konkretnej zbiórki.
               Ułatwieniem może być funkcja kopiowania linku do danej zbiórki, który znajdziesz na stronie jej poświęconej.
            `,
          },
          {
            title: 'Czym są subskrypcje w Serwisie?',
            content: `
              Subskrypcja jest zarejestrowaną w Serwisie deklaracją cyklicznego wspierania celu zdefiniowanego w danej zbiórce.
              <br />
              Ze względu na typ zbiórki, możemy wyróżnić zbiórki adopcyjne i kwotowe. W zbiórkach adopcyjnych Darczyńca decyduje o liczbie dzieciaków, których comiesięczne koszty utrzymania chce wziąć na siebie.
              W zbiórkach kwotowych natomiast, Darczyńca określa jaką część celu, wyrażonego kwotą miesięcznego wsparcia, decyduje się finansować.
              W tym celu decyduje o liczbie "zakupionych" cegiełek, których cenę ustalamy zwykle na poziomie 50zł miesięcznie.
              <br />
              <br />
              Mamy również podział ze względu na sposób realizowania płatności.
              Subskrypcja z pobraniem co miesiąc z karty polega, jak nazwa sugeruje, na automatycznym, comiesięcznym pobieraniu ustalonej kwoty z zarejestrowanej karty płatniczej.
              Wybierając ten wariant, w kolejnym kroku, zostaniemy przeniesieni na stronę Operatora Płatności - Przelewy24 w celu zdefiniowania numerów swojej karty.
              Proces zostanie zakończony pobraniem pierwszej miesięcznej darowizny, kolejne płatności realizować się będą automatycznie, bez zaangażowania Darczyńcy.
              <br />
              Subskrypcja - jednorazowy przelew za wybraną ilość miesięcy, jest dla tych Darczyńców, którzy wolą swoimi darowiznami sterować ręcznie. Po ustaleniu wysokości comiesięcznego wsparcia, Darczyńca podejmuje decyzję za ile miesięcy z góry opłaca swoją subskrypcję.
              W kolejnym kroku, z poziomu już Operatora Płatności - Przelewy 24 - Darczyńca realizuje przelew elektroniczny.
              Tak uruchomioną subskrypcję można przedłużać korzystając ze swojego konta w Serwisie -  na 2 tygodnie przed datą wygaśnięcia subskrypcji mailowo przypomnimy Darczyńcy o takiej możliwości.
            `,
          },
          {
            title: 'Czy Adopcja oznacza zobowiązanie wobec konkretnych dzieci?',
            content: 'Tzw. zbiórki adopcyjne zaprojektowaliśmy w taki sposób, aby Darczyńca, chcący regularnie wspierać dany ośrodek edukacyjny, decydował o liczbie dzieci, których miesięczne koszty utrzymania weźmie na siebie. W związku z tym decydując np: o adopcji 3 dzieci z sierocińca w Ivano - Frankowsku, decydujesz o comiesięcznym finansowaniu utrzymania trójki dzieciaków bez wskazywania ich z imienia i nazwiska.',
          },
          {
            title: 'Co się dzieje z moimi subskrypcjami w momencie zamknięcia zbiórki?',
            content: 'W momencie zamknięcia zbiórki przez Administratora Serwisu, wszystkie przypisane do tej zbiórki subskrypcje są automatycznie anulowane.',
          },
          {
            title: 'W jaki sposób Fundacja zabezpiecza dane z kart płatniczych Darczyńców?',
            content: 'Fundacja nie ma dostępu do żadnych danych związanych z kartami czy rachunkami bankowymi Darczyńców. ' +
              'Wszelkie operacje finansowe realizowane są w systemie informatycznym popularnego Operatora Płatności - Przelewy 24, który zobowiązany jest spełniać najwyższe standardy bezpieczeństwa.',
          },
          {
            title: 'Jak można zrezygnować z uruchomionej subskrypcji?',
            content: 'Po zalogowaniu na swoje konto, w sekcji Moje finanse, znajduje się okienko do zarządzania subskrypcjami. ' +
              'Przy każdej uruchomionej subskrypcji znajduje się przycisk "Anuluj", którego wciśnięcie umożliwia rezygnację z dalszego wspierania wybranego celu.',
          },
          {
            title: 'Czy można zrezygnować z wpłaty już zrealizowanej?',
            content: 'Tak, każdą wpłatę, regularną lub doraźną, traktujemy jako odrębną umowę darowizny i dajemy Darczyńcom prawo do odstąpienia od niej w okresie 14 dni od dnia dokonania wpłaty. ' +
              'W przypadku podjęcia takiej decyzji prosimy o wysłanie maila na pomoc@salvatti.edu.pl, tytuł "Rezygnacja z darowizy" a w treści prosimy o podanie następujących informacji: ' +
              '1.Nazwa zbiórki, 2.Data wpłaty, 3.Kwota, 4.Adres mailowy przypisany do konta w Serwisie lub podany na etapie realizacji płatności, w sytuacji, ' +
              'w której wpłata realizowana była bez logowania się na konto w Serwisie.',
          },
        ],
      },
      {
        title: 'Wolontariat',
        children: [
          {
            title: 'Wprowadzenie',
            content: 'Fundacja realizuje swoje zadania korzystając ze wsparcia wolontariuszy. ' +
              'Dla pallotyńskiej Fundacji to nie tylko urzeczywistnienie idei angażowania osób świeckich wokół dobrych projektów, ' +
              'ale również bardzo wymierna pomoc w integrowaniu, animowaniu i rozpoznawaniu rzeczywistych potrzeb społeczności, ' +
              'wśród której realizowana jest działalność misyjna. Dla wolontariuszy, z kolei to okazja, by doświadczyć, że więcej jest radości z dawania niż brania ' +
              'i dla wielu kilkutygodniowy pobyt wśród afrykańskich dzieci okazał się cennym resetem i przedefiniowaniem sensu swojego życia. ' +
              'Do wybuchu wojny w Ukrainie wolontariat Fundacji koncentrował się wokół pracy przy ośrodkach misyjnych, dzisiaj poszukujemy również osób,' +
              ' które zaangażują się w pomoc rodzinom ukraińskim, potrzebującym wsparcia na terenie RP. ',
          },
          {
            title: 'Chcę pomagać rodzinom z Ukrainy',
            content: 'Jeżeli dostrzegasz u siebie takie cechy jak chęć pomagania innym, dobra organizacja, sumienność, analityczne myślenie, zdolność improwizacji, ' +
              'a do tego potrafisz przekonywać do swoich racji innych, również wykorzystując do tego media społecznościowe to jesteś idealnym kandydatem na wolontariusza.' +
              ' Do Twoich zadań będzie należało: - docieranie do rodzin potrzebujących pomocy samodzielnie bądź poprzez Fundację, - analiza ich potrzeb materialnych, ' +
              'zaplanowanie optymalnego procesu dostarczania pomocy, - skompletowanie materiałów - opisów, zdjęć koniecznych do opracowania zbiórki w Serwisie,' +
              ' - promowanie "swoich" zbiórek w ramach posiadanego zasięgu, - dbanie o "swoje" zbiórki, aktualizowanie wpisów, monitorowanie sytuacji wspieranych rodzin,' +
              ' - bycie na bieżąco i doradzanie (PESEL, wniosek o dofinansowanie, rachunek bankowy, 500+ etc.) - koordynowanie procesu dostarczania pomocy, ew. rozliczanie się,' +
              ' gdy będzie się z czego rozliczać ... Jeśli widzisz się w takim projekcie, zapraszamy: Przedstaw się krótko w mailu, któremu nadasz tytuł: Wolontariat, i który wyślesz na pomoc@salvatti.edu.pl',
          },
          {
            title: 'Chcę pomagać w ośrodkach misyjnych',
            content: 'Fundacja stale poszukuje osób, które zechciałyby swoimi umiejętnościami i talentami wspomóc działania Fundacji w ośrodkach misyjnych w różnych zakątkach świata.' +
              ' Osoby zainteresowane taką formułą pomagania innym, po wstępnej kwalifikacji odbywają półroczne przygotowanie (spotkania weekendowe raz w miesiącu), ' +
              'po ukończeniu którego organizowany jest ich pierwszy turnus w uzgodnionej destynacji. Osoby zainteresowane zapraszamy do kontaktu: ' +
              'Przedstaw się krótko w mailu, któremu nadasz tytuł: Wolontariat misyjny, i który wyślesz na adres: pomoc@salvatti.edu.pl',
          },
        ],
      },
      {
        title: 'Współpraca',
        children: [
          {
            title: 'Wprowadzenie',
            content: 'Serwis salvatti.edu.pl został zaprojektowany w sposób umożliwiający przyjmowanie darowizn na rzecz innych organizacji, ' +
              'których profil działalności jest spójny z zakresem działalności Fundacji opisanym w jej Statucie. Formalnie rzecz ujmując, ' +
              'Fundacja jest w tej relacji Operatorem przyjmującym darowizny z poleceniem ich przekazania na rzecz danego Beneficjenta. ' +
              'Zapraszamy więc do współpracy inne wspólnoty zakonne, szkoły, organizacje pozarządowe, wszelkie szeroko definiowane inicjatywy realizujące podobne cele statutowe, ' +
              'które współfinansowane są ze środków pochodzących z darowizn.',
          },
           {
            title: 'Korzyści Beneficjenta',
            content: 'Wskazalibyśmy 3 najistotniejsze korzyści dla Beneficjenta. ' +
              'Po pierwsze jest to możliwość szybkiego uruchomienia swoich zbiórek w innowacyjnej, formule oferowanej przez System, w tym z wykorzystaniem mechanizmu darowizn cyklicznych. ' +
              'Po drugie, Beneficjent otrzymując dedykowane linki do swoich "projektów" zyskuje skuteczne narzędzie do promowania i poszerzania zasięgu swojej oferty. ' +
              'Po trzecie, wdrożone mechanizmy raportowe umożliwiają proste i zautomatyzowane utrzymywanie relacji ze swoimi Darczyńcami. ',
          },
           {
            title: 'Zobowiązania Beneficjenta',
            content: 'Przede wszystkim cele statutowe Beneficjenta i cele jego zbiórek muszą zawierać się w zakresie działalności Fundacji. ' +
              'Spełniając to kryterium, nawiązujemy współpracę, w ramach której Beneficjent zobowiązany jest przede wszystkim do dostarczenia wsadu merytorycznego czyli treści, ' +
              'zdjęć i argumentów, które po odpowiednim przygotowaniu przez naszych redaktorów, zamienią się w dobrze przygotowany i opublikowany w Serwisie projekt, ' +
              'który Darczyńcy będą chcieli wesprzeć finansowo. Po uruchomieniu zbiórki w Serwisie, Beneficjenci powinni aktywnie promować ją w ramach posiadanych zasięgów, ' +
              'aktualizować sekcję Posty & Galeria oraz dostarczać materiał do raportów, które rozsyłane Darczyńcom stanowią podziękowanie i ilustrację zrealizowanych dzięki ich wsparciu rezultatów.  ',
          },
           {
            title: 'Jak nawiązać współpracę',
            content: 'Potencjalnych Beneficjentów prosimy o kontakt mailowy na: pomoc@salvatti.edu.pl, w tytule "Współpraca", a w treści maila zwięzły opis organizacji i celów, jakie mają być wspierane w ramach Serwisu. ',
          },
        ]
      }
    ],
  },
  months_short: 'mc',
  slider_manage: {
    title: 'Zarządzanie banerem',
    btn_add: 'Dodaj',
    btn_save: 'Zapisz',
    slider_item: {
      title: 'Tytuł',
      linked_unit: 'Podlinkowana zbiórka',
      description: 'Opis',
      remove: 'Usuń',
      popover_delete: {
        btn_cancel: 'Nie',
        btn_confirm: 'Tak, usuń',
        confirm_text: 'Czy na pewno chcesz usunąć zdjęcie?',
      },
    },
    remove_image_error: 'Nie udało się usunąć zdjęcie',
    swap_delay: 'Czas zmiany zdjęcia (sek)',
    slider_content_update_error: 'Nie udało się zaktualizować baner',
    slider_content_update_success: 'Baner został zaktualizowany',
    fetch_slider_images_error: 'Nie udało się pobrać zdjęcia'
  }
};
export { pl };
