<template>
  <el-button v-bind="$attrs" v-on="$listeners" class="d-block w-100">
    <slot />
  </el-button>
</template>

<script>
export default {
  name: 'VWideButton'
}
</script>

