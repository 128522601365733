<template>
  <div id="app" class="app" v-loading="isLoading">
    <AppLayout v-if="!isLoading">
      <router-view/>
    </AppLayout>
  </div>
</template>

<script>
import AppLayout from './layouts/AppLayout'
import {mapActions} from 'vuex'

export default {
  components: {
    AppLayout
  },
  data() {
    return {
      isLoading: true
    }
  },
  beforeMount() {
    this.fetchUser()
  },
  methods: {
    ...mapActions(['authState/setUser']),
    async fetchUser() {
      this.isLoading = true
      await this['authState/setUser']().finally(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.app {
  min-height: 100vh;
}
</style>
