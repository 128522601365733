<template>
<div class="unit-payment-actions">
  <v-tile-button
      v-if="unitType === $options.UNIT_TYPE_INSTITUTION"
      plain
      icon="heart_broken"
      type="danger"
      @click="goToPayment($options.ADOPTION_PAYMENT_ROUTE_NAME)"
  >
    <template #default>
      {{ $t(`page_unit.btn_adoption`) }}
    </template>
    <template #icon>
      <svg v-svg symbol="adoption_icon" />
    </template>
  </v-tile-button>
  <v-tile-button
      v-if="unitType === $options.UNIT_TYPE_FAMILY"
      plain
      icon="heart_broken"
      type="danger"
      @click="goToPayment($options.FAMILY_PAYMENT_ROUTE_NAME)"
  >
    <template #default>
      {{ $t(`page_unit.btn_family_support`) }}
    </template>
    <template #icon>
      <svg v-svg symbol="adoption_icon" />
    </template>
  </v-tile-button>
  <v-tile-button
      @click="goToPayment($options.PURPOSE_PAYMENT_ROUTE_NAME)"
      plain
      icon="heart_broken"
      type="primary"
  >
    <template #default>
      {{ labelSupport }}
    </template>
    <template #icon>
      <svg v-svg symbol="adoption_icon" />
    </template>
  </v-tile-button>
</div>
</template>

<script>
import VTileButton from '../../../components/ui/v-tile-button/VTileButton'
import {
  UNIT_TYPE_FAMILY,
  UNIT_TYPE_INSTITUTION
} from '../../../admin/units/shared/constants/units-constants'
import {
  PURPOSE_PAYMENT_ROUTE_NAME,
  ADOPTION_PAYMENT_ROUTE_NAME,
  FAMILY_PAYMENT_ROUTE_NAME
} from '../../../shared/constants/app-routes-names'


export default {
  name: 'UnitActions',
  PURPOSE_PAYMENT_ROUTE_NAME,
  ADOPTION_PAYMENT_ROUTE_NAME,
  UNIT_TYPE_FAMILY,
  UNIT_TYPE_INSTITUTION,
  FAMILY_PAYMENT_ROUTE_NAME,
  components: {VTileButton},
  props: {
    unitType: {
      type: String,
      required: true
    }
  },
  computed: {
    labelSupport() {
      return this.$t(`page_unit.${this.unitType}.btn_unit_support`)
    }
  },
  methods: {
    goToPayment(routeName) {
      const {unitID} = this.$route.params
      this.$router.push({
        name: routeName,
        params: {
          unitID
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.unit-payment-actions {
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  @include tablet {
    justify-content: center;
    padding-top: 24px;
  }
  .el-button {
    margin-left: 0;
    margin-top: 15px;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
