<template>
  <el-row class="container-wrapper" :style="backgroundImage">
    <el-col class="center-container"
            :xs="{span: 22, offset: 1}"
            :sm="{span: 12, offset: 6}"
            :md="{span: 10, offset: 6}"
            :lg="{span: 7, offset: 8}"
            :xl="{span: 5, offset: 9}"
    >
      <slot />
    </el-col>
  </el-row>
</template>

<script>
import { CENTER_CONTAINER_LAYOUT_NAME } from '../../shared/constants/layout-names'

export default {
  name: CENTER_CONTAINER_LAYOUT_NAME,
  provide() {
    return {
      changeLayoutImage: this.changeLayoutImage
    }
  },
  data() {
    return {
      image: ''
    }
  },
  methods: {
    changeLayoutImage(img) {
      this.image = img
    }
  },
  computed: {
    backgroundImage() {
      return {
        background: 'no-repeat center center/cover',
        'background-image': `url(${this.image})`,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container-wrapper {
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
</style>
