
import Vue from 'vue'
import Vuex from 'vuex'
import { appStateModules } from './app/app-state'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ...appStateModules
  }
})
