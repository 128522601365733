import {
  PAGE_CONTACT_ROUTE_NAME,
  MAIN_PAGE_ROUTE_NAME,
  PAGE_ABOUT_ORGANIZATION,
  PAGE_FAQ,
} from '../shared/constants/app-routes-names';

import { BANNER_LAYOUT_NAME } from '../shared/constants/layout-names';

const HomePage = () => import('./home-page/HomePage');
const ContactPage = () => import('./contact-page/ContactPage');
const AdoptionStatic = () => import('./adoption-static/AdoptionStatic');
const FaqPage = () => import('./faq-page/FaqPage');

import { unitRoutes } from './unit/unit-routes';

const clientRoutes = [
  {
    path: '/',
    name: MAIN_PAGE_ROUTE_NAME,
    component: HomePage,
  },
  {
    path: '/contact',
    name: PAGE_CONTACT_ROUTE_NAME,
    component: ContactPage,
    meta: {
      layout: BANNER_LAYOUT_NAME,
    },
  },
  {
    path: '/about-salvatti',
    name: PAGE_ABOUT_ORGANIZATION,
    component: AdoptionStatic,
    meta: {
      layout: BANNER_LAYOUT_NAME,
    },
  },
  {
    path: '/faq',
    name: PAGE_FAQ,
    component: FaqPage,
  },
  ...unitRoutes,
];

export { clientRoutes };
