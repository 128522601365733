export default {
  namespaced: true,
  state: {
    refererCode: null
  },
  mutations: {
    SET_REFERER_CODE(state, code) {
      state.refererCode = code
    }
  }
}
