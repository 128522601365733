<template>
  <div>
    <el-menu
      :class="{
        'menu-mobile': isSm,
        'is-active': menuActive && isSm,
      }"
      :router="true"
      :default-active="$route.name"
    >
      <can-i
        :permissions="rt.permissions"
        v-for="(rt, idx) in allRoutes"
        :key="idx"
      >
        <el-menu-item
          @click="closeMenu"
          :route="{ name: rt.name }"
          :index="rt.name"
        >
          {{ $t(rt.label) }}
        </el-menu-item>
      </can-i>
      <el-menu-item
        v-if="!isLoggedIn"
        @click="closeMenu"
        :route="{ name: $options.USER_LOGIN_ROUTE_NAME }"
        :index="$options.USER_LOGIN_ROUTE_NAME"
      >
        {{ $t("navigation_menu.login") }}
      </el-menu-item>
    </el-menu>
    <v-sandwich :is-active.sync="menuActive" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VSandwich from "../../../../../components/ui/v-sandwich/VSandwich";
import CanI from "../../../../../auth/can-i/CanI";
import {
  PAGE_FAQ,
  PAGE_ABOUT_ORGANIZATION,
  USER_LOGIN_ROUTE_NAME,
} from "../../../../../shared/constants/app-routes-names";

import { adminRoutes, profileRoutes } from "../menu";

export default {
  name: "MenuMobile",
  components: { CanI, VSandwich },
  USER_LOGIN_ROUTE_NAME,
  adminRoutes,
  profileRoutes,
  inject: ["responsive"],
  data() {
    return {
      menuActive: false,
    };
  },
  methods: {
    closeMenu() {
      this.menuActive = false;
    },
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "authState/isLoggedIn",
    }),
    isSm() {
      return this.responsive.isSm;
    },
    allRoutes() {
      return [
        {
          label: "navigation_menu.about_us",
          name: PAGE_ABOUT_ORGANIZATION,
          permissions: [],
        },
        {
          label: "navigation_menu.faq",
          name: PAGE_FAQ,
          permissions: [],
        },
        ...this.$options.adminRoutes,
        ...this.$options.profileRoutes,
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.menu-mobile {
  margin-top: -200px;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  border-width: 1px 0 1px;
  .el-menu-item {
    display: none;
  }

  &.is {
    &-active {
      margin: 0 -20px 0;
      visibility: visible;
      opacity: 0.98;
      transition: all 0.5s ease-out;

      .el-menu-item {
        text-align: center;
        float: none;
        display: block;
        height: 100%;
        width: 100%;
        border-top: 1px solid #eaeaeb;
        font-size: 18px;
      }
    }
  }
}
</style>
