import {NOT_FOUND_ROUTE_NAME} from '../shared/constants/app-routes-names'
const PageNotFound = () => import('./page-not-found/PageNotFound')


const servicePagesRoutes = [
  {
    path: '/not-found',
    name: NOT_FOUND_ROUTE_NAME,
    component: PageNotFound
  },
  {
    path: '**',
    redirect: { name: NOT_FOUND_ROUTE_NAME }
  }
]


export {servicePagesRoutes}
