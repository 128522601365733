<template>
  <UnitProgressRenderless
      :total="unitProgress.total"
      :progress="unitProgress.progress"
  >
    <template #default="{currentProgress, progressPercentage, progressDifference}">
      <div class="unit-progress">
        <el-progress color="#38D6C1" :percentage="progressPercentage"></el-progress>
        <div class="unit-funding">
          <div>
            <div class="heading heading--unit-statistic">
              {{ parseProgressValue(currentProgress) }}
            </div>
            <div>
              {{ labelCurrentProgress }}
            </div>
          </div>
          <div>
            <div class="heading heading--unit-statistic">
              {{ parseProgressValue(progressDifference) }}
            </div>
            <div>{{ labelDifference }}</div>
          </div>
        </div>
      </div>
    </template>
  </UnitProgressRenderless>
</template>

<script>
import {moneyFormat} from '../../../../shared/utils/moneyFormat'
import UnitProgressRenderless from './UnitProgressRenderless'
import {
  UNIT_TYPE_EMERGENCY,
  UNIT_TYPE_INSTITUTION,
  UNIT_TYPE_FAMILY
} from '../../../../admin/units/shared/constants/units-constants'

export default {
  name: 'UnitProgress',
  components: {UnitProgressRenderless},
  props: {
    unitProgress: {
      type: Object,
      required: true
    },
    unitType: {
      type: String,
      required: true
    }
  },
  methods: {
    parseProgressValue(v) {
      const PROGRESS_VALUE_FORMAT = {
        [UNIT_TYPE_EMERGENCY]: moneyFormat(v),
        [UNIT_TYPE_FAMILY]: `${moneyFormat(v)}/${this.$t('months_short')}`,
        [UNIT_TYPE_INSTITUTION]: v
      }

      return PROGRESS_VALUE_FORMAT[this.unitType]
    }
  },
  computed: {
    labelDifference() {
      return this.$t(`unitProgressLabels.${this.unitType}.difference`)
    },
    labelCurrentProgress() {
      return this.$t(`unitProgressLabels.${this.unitType}.currentProgress`)
    }
  }
}
</script>

<style scoped>

.unit-progress {
  margin-top: 1.2em;
}

.unit-funding {
  margin-top: 0.5em;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
</style>
