<template>
  <div class="unit-card-wrapper">
    <el-card body-style="padding: 0">
      <div class="image-wrapper">
        <img
            :src="photoSrc"
            class="unit-card-image"
        />
      </div>
      <div class="unit-card-content">
        <UnitProgress
            :unit-type="unitType"
            :unit-progress="unitProgress"
        />
      </div>
      <div>
        <v-wide-button size="default" type="primary" @click="copyUnitLink">
          {{ $t('page_unit.btn_copy_unit_link') }}
        </v-wide-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import UnitProgress from '../../components/units/unit-progress/UnitProgress'
import { UPLOADS_FOLDER_URL } from '../../../shared/constants/backend'
import VWideButton from '../../../components/ui/v-wide-button/VWideButton'
import {copyToClipboard} from '../../../shared/utils/copyToClipboard'
import {mapState} from 'vuex'
import {VOLUNTEER_CODE_QUERY_KEY} from '../../../shared/constants/unit-constants'

export default {
  name: 'UnitCard',
  components: {VWideButton, UnitProgress},
  props: {
    unitProgress: {
      type: Object,
      required: true
    },
    unitPhoto: {
      type: String
    },
    unitType: {
      type: String,
      required: true
    }
  },
  computed: {
    photoSrc() {
      return `${UPLOADS_FOLDER_URL}${this.unitPhoto}`
    },
    ...mapState('authState', {
      user: state => state.user,
    }),
  },
  methods: {
    copyUnitLink() {
      const { origin, pathname } = window.location
      let link = `${origin}${pathname}`
      if (this.user?.referenceCode) {
        link += `?${VOLUNTEER_CODE_QUERY_KEY}=${this.user.referenceCode}`
      }

      copyToClipboard(link).then((res) => {
        this.$message({
          showClose: false,
          message: `${this.$t(res)}`,
          type: 'success'
        })
      })
      .catch(([err, reason]) => {
        this.$message({
          showClose: false,
          message: `${this.$t(err)}: ${reason}`,
          type: 'error'
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";

.image-wrapper {
  position: relative;
  padding-bottom: 15.4em;
}
.unit-card-wrapper {
  z-index: 100;
  margin-top: -100%;
  position: sticky;
  top: 2em;
  @include tablet {
    max-width: 300px;
    margin: -15% auto 0;
    position: static;
  }
  @include xs-smartphone {
    margin: -25% auto 0;
  }
}
.unit-card-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.unit-card-content {
  padding: 24px 12px;
}
.el-card {
  border: 0;
}
.el-button {
  border-radius: 0;
}
.unit-progress {
  margin-top: 0;
}
</style>
