import axios from 'axios'
import store from '../../../store'
import router from '../../../router'

import {
  NOT_FOUND_ROUTE_NAME,
  USER_LOGIN_ROUTE_NAME
} from '../constants/app-routes-names'

const BASE_DOMAIN = process.env.VUE_APP_BACKEND_URL
const BASE_URL = `${BASE_DOMAIN}/api`

const UNAUTHORIZED_STATUS_CODE = 401
const UNAUTHORIZED_MESSAGE = 'alerts.session_expired'
const ACCESS_DENIED_STATUS_CODE = 403
const ACCESS_DENIED_MESSAGE = 'alerts.access_denied'

const api = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})


api.interceptors.request.use(async (config) => {
  await store.dispatch('loadingState/setLoadingState', true)
  return config
}, ((error) => {
  return Promise.reject(error)
}))

api.interceptors.response.use(
  async (res) => {
    await store.dispatch('loadingState/setLoadingState', false)
    return res
  },
  async (error) => {
    await store.dispatch('loadingState/setLoadingState', false)
    const { status } = error.response
    if (status === UNAUTHORIZED_STATUS_CODE) {
      await router.push({name: USER_LOGIN_ROUTE_NAME, params: {back: true}})
      return Promise.reject(UNAUTHORIZED_MESSAGE)
    }
    if (status === ACCESS_DENIED_STATUS_CODE) {
      await router.push({name: NOT_FOUND_ROUTE_NAME})
      return Promise.reject(ACCESS_DENIED_MESSAGE)
    }
    return Promise.reject(error.response)
  },
)

export {api}
