<template>
  <el-row>
    <div class="navigation-wrapper">
      <router-link :to="{name: $options.MAIN_PAGE_ROUTE_NAME}" class="logo-link">
        <img :src="$options.logoImg" alt="SALVATTI Logo" class="logo-img">
      </router-link>
      <menu-mobile v-if="showMenuMobile" />
      <menu-desktop  v-else />
    </div>
  </el-row>
</template>

<script>
import {
  MAIN_PAGE_ROUTE_NAME,
} from '../../../shared/constants/app-routes-names'
import logoImg from '../../../../assets/images/salvatti_logo.png'
import MenuDesktop from './components/menu-desktop/MenuDesktop'
import MenuMobile from './components/menu-mobile/MenuMobile'

export default {
  name: 'VTopNavigation',
  components: {MenuMobile, MenuDesktop},
  logoImg,
  MAIN_PAGE_ROUTE_NAME,
  data() {
    return {
      menuActive: false,
    }
  },
  inject: ['responsive'],
  computed: {
    showMenuMobile() {
      return this.responsive.isSm
    }
  },
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.spacer {
  width: 100%;
}


.navigation-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  @include smartphone {
    display: block;
  }
}
</style>
