<template>
  <AppLayoutTopNavigation>
    <div class="unit-page-wrapper">
      <div
          class="loader"
          v-if="isLoading"
          v-loading="isLoading"
          element-loading-background="rgba(255, 255, 255, 1)">
      </div>
      <div v-else>
        <UnitBanner
            :unit-title="unit.title"
            :unit-banner-name="unit.banner"
        />
        <div class="app-container">
          <el-row :gutter="24">
            <el-col class="z-index-1" :lg="6"
            >
              <UnitCard
                  :unit-progress="unit.progressData"
                  :unit-photo="unit.photo"
                  :unit-type="unit.unitType"
              />
            </el-col>
            <el-col :lg="13" class="unit-content-column">
              <el-menu class="navigation-custom" mode="horizontal" :router="true" :default-active="$route.name">
                <el-menu-item
                    v-for="rt in $options.unitRoutes"
                    :key="rt.name"
                    :route="{ name: rt.name}"
                    :index="rt.name"
                >
                  {{ $t(`unit_navigation.${rt.name}`) }}
                </el-menu-item>
              </el-menu>
              <div class="unit-content">
                <router-view />
              </div>
            </el-col>
            <el-col :lg="4">
              <UnitActions :unit-type="unit.unitType" />
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </AppLayoutTopNavigation>
</template>

<script>
import UnitActions from '../../client/unit-page/unit-actions/UnitActions'
import UnitCard from '../../client/unit-page/unit-card/UnitCard'
import UnitBanner from '../../client/unit-page/unit-banner/UnitBanner'
import {fetchUnitPreviewData} from '../../client/unit-page/services/unit-sevice'
import AppLayoutTopNavigation from '../AppLayoutTopNavigation/AppLayoutTopNavigation'

import {UNIT_LAYOUT_NAME} from '../../shared/constants/layout-names'
import {VOLUNTEER_CODE_QUERY_KEY} from '../../shared/constants/unit-constants'

import {
  UNIT_ABOUT_ROUTE_NAME,
  UNIT_PURPOSES_ROUTE_NAME,
  UNIT_POSTS_ROUTE_NAME,
  UNIT_GALLERY_ROUTE_NAME,
} from '../../shared/constants/app-routes-names'
import {mapMutations} from 'vuex'

export default {
  name: UNIT_LAYOUT_NAME,
  components: {AppLayoutTopNavigation, UnitBanner, UnitCard, UnitActions},
  unitRoutes: [
    {name: UNIT_ABOUT_ROUTE_NAME},
    {name: UNIT_PURPOSES_ROUTE_NAME},
    {name: UNIT_POSTS_ROUTE_NAME},
    {name: UNIT_GALLERY_ROUTE_NAME},
  ],
  data() {
    return {
      isLoading: true,
      unit: {
        progressData: {},
        unitType: ''
      },
    }
  },
  provide() {
    return {
      unitID: this.unitID,
      accessUnitType: this.accessUnitType
    }
  },
  created() {
    this.fetchUnit()
    this.setRefererCode()
  },
  methods: {
    ...mapMutations('unitState', ['SET_REFERER_CODE']),
    accessUnitType() {
      return this.unit.unitType
    },
    setRefererCode() {
      const { query } = this.$route
      if (query[VOLUNTEER_CODE_QUERY_KEY]) {
       this.SET_REFERER_CODE(query[VOLUNTEER_CODE_QUERY_KEY])
      }
    },
    fetchUnit() {
      const {unitID} = this.$route.params
      this.isLoading = true
      fetchUnitPreviewData(unitID)
          .then((res) => {
            this.unit = res
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  },
  computed: {
    unitID() {
      return this.$route.params.unitID
    }
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/scss/utils/variables";
@import "src/assets/scss/utils/grid-mixins";
.z-index-1 {
  z-index: 1;
}
.unit-content {
  padding-top: 1.7em;
}
.navigation-custom {
  @include tablet {
    margin-top: 2em;
  }
  & >.el-menu-item {
    height: 40px;
    line-height: 40px;
  }
}
.unit-content-column {
  @include tablet {
    order: 1;
  }
}
</style>
