import {CENTER_CONTAINER_LAYOUT_NAME} from '../shared/constants/layout-names'

import {
  USER_LOGIN_ROUTE_NAME,
  USER_LOGOUT_ROUTE_NAME
} from '../shared/constants/app-routes-names'

const AuthRegister = () => import('./register/AuthRegister')
const AuthLogin = () => import('./login/AuthLogin')
const AuthConfirmEmail = () => import('./confirm-email/AuthConfirmEmail')
const AuthLogout = () => import('./logout/AuthLogout')
const AuthResetPassword = () => import('./reset-password/AuthResetPassword')
const AuthSetNewPassword = () => import('./set-new-password/AuthSetNewPassword')


const authRoutes = [
  {
    path: '/register',
    name: 'register',
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthRegister
  },
  {
    path: '/login',
    name: USER_LOGIN_ROUTE_NAME,
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthLogin
  },
  {
    path: '/confirm-email/:token',
    name: 'confirm-email',
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthConfirmEmail
  },
  {
    path: '/logout',
    name: USER_LOGOUT_ROUTE_NAME,
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthLogout
  },
  {
    path: '/forgot-password/:token',
    name: 'reset-password',
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthResetPassword
  },
  {
    path: '/forgot-password/new/:token',
    name: 'reset-password-new',
    meta: {
      layout: CENTER_CONTAINER_LAYOUT_NAME
    },
    component: AuthSetNewPassword
  }
]

export {
  authRoutes
}
