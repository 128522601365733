<script>
import {mapState} from 'vuex'

export default {
  name: 'CanI',
  props: {
    permissions: {
      type: Array,
      required: true,
    },
    passThrough: {
      type: Boolean,
    }
  },
  render() {
    if (this.allow || this.passThrough) {
      return this.$scopedSlots.default({
        allow: this.allow
      })
    }
    return {}
  },
  computed: {
    ...mapState('authState', {
      userPermissions: (state) => state.userPermissions
    }),
    allow() {
      const uniqueArray = new Set([...this.userPermissions, ...this.permissions])

      return uniqueArray.size === this.userPermissions.length
    }
  }
}
</script>
