<script>
export default {
  name: 'UnitProgressRenderless',
  props: {
    total: {
      type: Number,
    },
    progress: {
      type: Number
    },
  },
  computed: {
    progressResult() {
      const result = (this.progress * 100) / this.total
      if (isNaN(result)) {
        return 0
      }
      return Math.round(result)
    },
    progressPercentage() {
      const MAX_PERCENTAGE = 100
      const percentage = Math.min(this.progressResult, MAX_PERCENTAGE)
      return Math.round(percentage)
    },
    progressDifference() {
      if (this.progress > this.total) {
        return 0
      }
      return this.total - this.progress
    }
  },
  render() {
    return this.$scopedSlots.default({
      progressDifference: this.progressDifference,
      currentProgress: this.progress,
      progressPercentage: this.progressResult
    })
  }
}
</script>
