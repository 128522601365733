<template>
  <el-button ref="button" v-bind="$attrs" v-on="$listeners">
    <div class="button-container">
      <v-icon size="18px" v-if="icon" :name="icon"/>
      <slot />
    </div>
  </el-button>
</template>

<script>
import VIcon from '../v-icon/VIcon'
export default {
  name: 'VButton',
  components: {VIcon},
  props: {
    icon: {
      required: false,
      default: undefined
    },
  },
  methods: {
    blur() {
      this.$refs.button.$el.blur()
    }
  }
}
</script>

<style scoped lang="scss">
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  .material-icons-round {
    margin-right: 3px;
    font-size: 18px;
  }
}
</style>
