import loadingState from './loading/loading-state'
import authState from './auth/auth-state'
import unitState from './client/unit/unit-state'

const appStateModules = {
  loadingState,
  authState,
  unitState
}

export {
  appStateModules
}
