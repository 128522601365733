export default {
  namespaced: true,
  state: {
    isLoading: false
  },
  mutations: {
    SET_LOADING_STATE(state, value) {
      state.isLoading = value
    }
  },
  actions: {
    setLoadingState({commit}, isLoading) {
      commit('SET_LOADING_STATE', isLoading)
    }
  },
}
