<template>
  <span :style="style" class="v-icon material-icons-round">
    <slot>
      {{ name }}
    </slot>
  </span>
</template>

<script>
import 'material-icons/iconfont/round.scss'
export default {
  name: 'VIcon',
  props: {
    name: {
      type: String
    },
    color: {
      type: String,
      default: 'inherit'
    },
    size: {
      type: String,
      default: '17px'
    }
  },
  computed: {
    style() {
      return {
        color: this.color,
        fontSize: this.size
      }
    }
  }
}
</script>
