export const PURPOSE_PAYMENT_ROUTE_NAME = 'purpose_payment'
export const STANDING_ORDER_PAYMENT_ROUTE_NAME = 'standing_order_payment'
export const FAMILY_PAYMENT_ROUTE_NAME = 'family_payment'
export const ADOPTION_PAYMENT_ROUTE_NAME = 'adoption_payment'
export const RENEW_SUBSCRIPTION_ROUTE_NAME = 'renew_subscription'
export const COMPLETE_PAYMENT_ROUTE_NAME = 'complete_payment'
export const PROFILE_ACCOUNT_ROUTE_NAME = 'profile_account'
export const PROFILE_VOLUNTEER_ROUTE_NAME = 'profile_volunteer'
export const PROFILE_ORDERS_ROUTE_NAME = 'profile_orders'
export const MAIN_PAGE_ROUTE_NAME = 'home'
export const NOT_FOUND_ROUTE_NAME = 'not_found'
export const POST_CREATE_ROUTE_NAME = 'post_create'
export const POST_EDIT_ROUTE_NAME = 'post_edit'
export const POST_VIEW_ROUTE_NAME = 'post_view'
export const UNIT_POSTS_ROUTE_NAME = 'unit_posts'
export const UNIT_ABOUT_ROUTE_NAME = 'unit_about'
export const UNIT_PURPOSES_ROUTE_NAME = 'unit_purposes'
export const UNIT_GALLERY_ROUTE_NAME = 'unit_gallery'
export const ADMIN_UNIT_LIST_ROUTE_NAME = 'unit_list'
export const ADMIN_USER_LIST_ROUTE_NAME = 'admin_user_list'
export const ADMIN_UNIT_GALLERY_ROUTE_NAME = 'admin_unit_gallery'
export const ADMIN_VOLUNTEERS_ROUTE_NAME = 'admin_volunteers'
export const ADMIN_FINANCE_REPORT_ROUTE_NAME = 'admin_finance_report'
export const ADMIN_QUARTER_REPORT_ROUTE_NAME = 'admin_quarter_report'
export const ADMIN_ADD_QUARTER_REPORT_ROUTE_NAME = 'admin_add_quarter_report'
export const ADMIN_SLIDER_MANAGE = 'admin_add_slider_manage'
export const USER_LOGIN_ROUTE_NAME = 'login'
export const USER_LOGOUT_ROUTE_NAME = 'logout'
export const PAGE_CONTACT_ROUTE_NAME = 'page_contact'
export const PAGE_ABOUT_ORGANIZATION = 'about_organization'
export const PAGE_FAQ = 'page_faq'
