import { fetchCurrentUser } from './shared/services/auth'
import { fetchPermissions } from './shared/services/auth'

export default {
  namespaced: true,
  state: {
    user: null,
    userPermissions: [],
    permissions: []
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_USER_PERMISSIONS(state, userPermissions) {
      state.userPermissions = userPermissions
    },
    REMOVE_USER_DATA(state) {
      state.user = null
      state.userPermissions = []
    }
  },
  actions: {
    async fetchPermissionList({commit}) {
      const permissionList = await fetchPermissions()
      commit('SET_PERMISSIONS', permissionList)
    },
    async setUser({commit}) {
      const {userInfo, permissions} = await fetchCurrentUser()
      commit('SET_USER', userInfo)
      commit('SET_USER_PERMISSIONS', permissions)
    }
  },
  getters: {
    isLoggedIn: state => Boolean(state.user),
    isGrantedTo: (state) => (permission) => {
      return state.userPermissions.includes(permission)
    },
    getUserEmail: state => state.user.email,
  }
}
